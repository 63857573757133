import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography,
  TextField,
  FormControlLabel,
} from '@material-ui/core';
import TimePadding from '../TimePadding';
import FilterSettings from '../FilterSettings';
import ArtifactDetection from '../ArtifactDetectionSettings';
import CategoriesTable from '../CategoriesTable';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

/**
 * EditErpSettingsDialog
 *
 * renders a modal dialog to change workflow settings
 *
 * @param {Object} props - component's properties
 * @param {function} props.open - invoked when dialog is opened
 * @param {string[]} props.availableCodes - event codes in current mff file
 * @param {object} props.value - contains values for controls
 * @param {function} props.onClose - invoked when dialog is closed
 * @returns {React.Component} - returns a modal dialog containing a set of controls
 */
const EditErpSettingsDialog = ({
  open,
  availableCodes,
  value,
  onClose,
}) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [categories, setCategories] = useState({});
  const [padding, setPadding] = useState({ left: 2000, right: 2000 });
  const [enabledFilters, setEnabledFilters] = useState({ highpass: true, lowpass: true });
  const [cutoff, setCutoff] = useState({ highpass: 0.1, lowpass: 40 });
  const [filterOrder, setFilterOrder] = useState(4);
  const [dropBadSegments, setDropBadSegments] = useState(true);
  const [amplitudeCriteria, setAmplitudeCriteria] = useState(200);
  const [avgRef, setAvgRef] = useState(true);

  const handleEnter = () => {
    const entries = Object.entries(value.categories);
    const validEntries = entries.filter((entry) => availableCodes.includes(entry[1]));
    const validCategories = validEntries.length === 0 ? {} : Object.fromEntries(validEntries);
    const { filterSettings, artifactDetection } = value;
    setName(value.name);
    setDescription(value.description);
    setCategories(validCategories);
    setPadding({
      left: value.padding.left * 1000,
      right: value.padding.right * 1000,
    });
    setEnabledFilters(filterSettings.enabled);
    setCutoff(filterSettings.cutoff);
    setFilterOrder(filterSettings.order);
    setDropBadSegments(artifactDetection.dropBadSegments);
    setAmplitudeCriteria(artifactDetection.amplitudeCriteria);
    setAvgRef(value.avgRef);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleSave = () => {
    const editedSettings = {
      name,
      description,
      categories,
      padding: {
        left: padding.left / 1000,
        right: padding.right / 1000,
      },
      filterSettings: {
        enabled: enabledFilters,
        cutoff,
        order: filterOrder,
      },
      artifactDetection: {
        dropBadSegments,
        amplitudeCriteria,
      },
      avgRef,
    };
    onClose({ ...value, ...editedSettings });
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onEnter={handleEnter} onClose={handleCancel} fullWidth maxWidth="sm">
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={1}
            justify="center"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={3}>
                <Typography variant="subtitle1" gutterBottom>
                  1. Configuration name
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      id="config-name"
                      label="Name"
                      value={name}
                      onChange={({ target }) => setName(target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      id="config-description"
                      label="Description"
                      value={description}
                      onChange={({ target }) => setDescription(target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={3}>
                <Typography variant="subtitle1" gutterBottom>
                  2. Filter settings
                </Typography>
                <FilterSettings
                  enabled={enabledFilters}
                  cutoff={cutoff}
                  order={filterOrder}
                  onChangeEnabledFilters={setEnabledFilters}
                  onChangeCutoffFreq={setCutoff}
                  onChangeFilterOrder={setFilterOrder}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={3}>
                <Typography variant="subtitle1" gutterBottom>
                  3. Segmentation
                </Typography>
                <Grid container spacing={2} justify="center" alignItems="center">
                  <Grid item xs={8}>
                    <Grid container spacing={2} justify="center" alignItems="center">
                      <Grid item xs={12}>
                        <CategoriesTable
                          availableCodes={availableCodes}
                          categories={categories}
                          onChangeCategories={setCategories}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <TimePadding
                      padding={padding}
                      onChange={setPadding}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={3}>
                <Typography variant="subtitle1" gutterBottom>
                  4. Artifact detection
                </Typography>
                <ArtifactDetection
                  dropBadSegments={dropBadSegments}
                  amplitudeCriteria={amplitudeCriteria}
                  onChangeDropBadSegments={setDropBadSegments}
                  onChangeAmplitudeCriteria={setAmplitudeCriteria}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={3}>
                <Typography variant="subtitle1" gutterBottom>
                  5. Averaging
                </Typography>
                <Grid container spacing={3} justify="center" alignItems="center">
                  <Grid item xs={12}>
                    <span>No input parameters</span>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={3}>
                <Typography variant="subtitle1" gutterBottom>
                  6. Reference
                </Typography>
                <Grid container spacing={3} justify="center" alignItems="center">
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={avgRef}
                          onChange={({ target }) => setAvgRef(target.checked)}
                          name="avgRef"
                        />
                      )}
                      label="Apply average reference"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            color="primary"
            variant="contained"
            size="small"
            style={{
              paddingTop: '1px',
              paddingBottom: '1px',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            size="small"
            style={{
              paddingTop: '1px',
              paddingBottom: '1px',
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EditErpSettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  value: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditErpSettingsDialog;
