import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Divider } from '@material-ui/core';

import SvgIcon from '../icons';
import packageInfo from '../../package.json';

const mail = 'info@bel.company';
const standards = [
  'CFR Title 21 part 820',
  'ISO 13485:2016 - Medical devices — Quality management systems — Requirements for regulatory purposes',
  'ISO 14971:2019 - Medical devices — Application of risk management to medical devices',
  'Medical Device Regulation (EU 2017/745)',
  'IEC 62304:2006- Medical device software — Software life cycle processes',
];

const useStyles = makeStyles(() => ({
  paper: {
    padding: '16px',
  },
  image: {
    verticalAlign: 'bottom',
    width: '50px',
  },
}));

/**
 * AboutSection
 *
 * renders an elevated surface that displays info about the aplication
 *
 * @returns {React.Component} - paper component
 */
const AboutSection = () => {
  const classes = useStyles();
  const { image } = classes;
  const ReadManualIcon = SvgIcon({ name: 'ReadManual', className: image });
  const BelInfoIcon = SvgIcon({ name: 'BelInfo', className: image });

  return (
    <Paper className={classes.paper} elevation={2}>
      <Typography variant="h6">{`FLOW v${packageInfo.version}`}</Typography>
      <Divider />
      <p style={{ paddingTop: '20px' }}>Management of data and scheduling of analytic workflows.</p>
      <p>
        <ReadManualIcon />
        <span style={{ paddingLeft: '5px' }}>
          <a href="https://bel.company/support/user-manuals" target="_blank" rel="noreferrer">
            Read the manual before using the device.
          </a>
        </span>
      </p>
      <p>
        <BelInfoIcon />
        <span style={{ paddingLeft: '5px' }}>Brain Electrophysiology Laboratory Company, LLC</span>
        <br />
        <span>1776 Millrace Dr. St 304, Eugene OR 97403 USA</span>
        <br />
        <span>+1 541 525 9799</span>
        <br />
        <span><a href={`mailto:${mail}`}>{mail}</a></span>
      </p>
      <p>
        <p>
          This device has been developed under strict quality management system
          of BEL Company, LLC in accordance with the following standards:
        </p>
        <ul>{standards.map((item) => <li>{item}</li>)}</ul>
      </p>
    </Paper>
  );
};

export default AboutSection;
