import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';

import { login, getCurrentUser } from '../services/authService';
import LoginLayout from '../layouts/Login';

function Login() {
  const history = useHistory();
  const location = useLocation();
  const user = getCurrentUser();
  const [errors, setErrors] = useState(null);

  /* attempt login with username and password */
  const loginCallback = async (username, password) => {
    try {
      await login(username, password);
      history.push('/');
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setErrors({ username: err.response.data });
      }
    }
  };

  return (
    <>
      {location.state?.message !== undefined && (
        <Alert severity="warning">
          <AlertTitle>You have been logged out</AlertTitle>
          {location.state.message}
        </Alert>
      )}
      <LoginLayout
        user={user}
        login={loginCallback}
        errors={errors}
      />
    </>
  );
}

export default Login;
