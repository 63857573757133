import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';

import ConvertToMffDialog from './modal/ConvertToMffDialog';

/**
 * ConvertToMffMenuItem
 *
 * renders a menu item with a modal dialog to select
 * the input parameters for an edf-to-mff conversion.
 *
 * @param {Object} props - component's properties
 * @param {function} props.onClick - invoked when menu item is clicked
 * @param {function} props.onClose - invoked when dialog is being closed
 * @param {Object.<string, string>} props.fileProps - edf file properties
 * @returns {React.Component} - menu item, shows ConvertToMffDialog componet
 */
const ConvertToMffMenuItem = ({ onClick, onClose, fileProps }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    onClick();
    setOpen(true);
  };

  const handleClose = (sensornet, timezone) => {
    setOpen(false);
    onClose(sensornet, timezone);
  };

  return ['edf', 'bdf'].includes(fileProps.fileType) && (
    <div>
      <MenuItem onClick={handleClickOpen}>Convert to MFF</MenuItem>
      <ConvertToMffDialog
        open={open}
        onClose={handleClose}
        fileProps={fileProps}
      />
    </div>
  );
};

ConvertToMffMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  fileProps: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ConvertToMffMenuItem;
