import React from 'react';
import { Redirect } from 'react-router-dom';
import { getCurrentUser } from '../services/authService';

/**
 * Component for route '/'
 *
 * Redirects to login page if not logged in, else it redirects to
 * '/experiments'.
 */
function Home() {
  const user = getCurrentUser();
  const destination = user ? '/experiments' : '/login';
  return (
    <Redirect to={destination} />
  );
}

export default Home;
