import MaterialTable from '@material-table/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import LicensesInUse from './LicensesInUse';
import License from '../services/license';
import LicenseSocket from '../services/license_socket';

/**
 * Component used to list all licenses as well as a summary of those in use for each feature
 *
 * @prop {LicenseSocket} licenseSocket - The license socket used by the main application. Used by
 * this component to re-fetch licenses if it changes. This accounts for self-acquisition of
 * licenses.
 */
const Licenses = ({ licenseSocket }) => {
  const theme = useTheme();
  const [licenses, setLicenses] = useState([]);

  const fetchData = async () => {
    const newLicenses = await License.getLicenses();
    setLicenses(newLicenses);
  };

  React.useEffect(() => {
    fetchData();
  }, [licenseSocket]);

  async function handleFreeLicense(event, rowData) {
    const licenseId = rowData.uuid;
    await License.revoke(licenseId);
    await fetchData();
  }

  /**
   * Checks if an expirationDate value corresponds to an expired date or not
   *
   * @param {string} expirationDate
   * @returns {boolean}
   */
  const dateIsExpired = (expirationDate) => (
    expirationDate !== null && new Date(expirationDate) < new Date()
  );

  const columns = [
    { title: 'ID', field: 'uuid', cellStyle: { whiteSpace: 'nowrap' } },
    {
      title: 'Module',
      field: 'feature',
      render: ({ feature }) => (
        feature === 'belview' ? 'BEL VIEW' : feature.toUpperCase()
      ),
    },
    {
      title: 'Expiration Date',
      field: 'expirationDate',
      render: ({ expirationDate }) => (
        expirationDate === null ? 'No Expiration Date' : new Date(expirationDate).toLocaleString()
      ),
      cellStyle: (expirationDate) => ({
        ...(dateIsExpired(expirationDate) && {
          color: theme.palette.error.main,
        }),
      }),
    },
    {
      title: 'In Use By',
      field: 'usedBy',
      defaultSort: 'asc',
      customSort: (a, b) => {
        // Order expired dates at the bottom with oldest being last
        if (dateIsExpired(a.expirationDate) && dateIsExpired(b.expirationDate)) {
          return new Date(b.expirationDate) - new Date(a.expirationDate);
        } else if (dateIsExpired(a.expirationDate)) {
          return 1;
        } else if (dateIsExpired(b.expirationDate)) {
          return -1;
        }

        // Order licenses in use at the top, alphabetically by username
        if (a.usedBy !== '' && b.usedBy === '') {
          return -1;
        } else if (a.usedBy === '' && b.usedBy !== '') {
          return 1;
        } else if (a.usedBy < b.usedBy) {
          return -1;
        } else if (a.usedBy > b.usedBy) {
          return 1;
        }
        return 0;
      },
    },
  ];

  const actions = [
    (rowData) => ({
      icon: 'block',
      tooltip: 'Revoke a license',
      onClick: handleFreeLicense,
      disabled: rowData.usedBy === '',
    }),
  ];

  return (
    <>
      <LicensesInUse licenses={licenses} />
      <MaterialTable
        title="Licenses"
        columns={columns}
        actions={actions}
        data={licenses}
        options={{
          paging: false,
        }}
      />
    </>
  );
};

Licenses.propTypes = {
  licenseSocket: PropTypes.instanceOf(LicenseSocket),
};

Licenses.defaultProps = {
  licenseSocket: null,
};

export default Licenses;
