import http from './httpService';

const baseUrl = '/api/ssh';

async function startConnection() {
  await http.post(`${baseUrl}/start`);
}

async function stopConnection() {
  await http.post(`${baseUrl}/stop`);
}

async function isEnabled() {
  const { data: enabled } = await http.get(`${baseUrl}/status`);
  return enabled;
}

export {
  startConnection,
  stopConnection,
  isEnabled,
};
