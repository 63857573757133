import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import http from '../../services/httpService';
import Select from '../common/CustomSelect';
import TimeZones from '../select/Timezones';

/**
 * returns sensornets from the backend
 *
 * @param {Object} fileProps - edf file properties
 * @param {string} fileProps.fileId - an edf file ID
 * @param {string} fileProps.entityId - a patient or experiment ID
 * @param {bool} sorted - if true, the returned sensornets are sorted
 * @returns {string[]} - sensornets compatible with the passed edf file
 */
async function getSensornets(fileProps, sorted = false) {
  const { fileId, entityId } = fileProps;
  const url = `/api/edf2mff/sensornets/${entityId}/${fileId}`;
  const response = await http.get(url);
  const { data } = response;
  if (sorted) {
    return data.sort();
  }
  return data;
}

/**
 * returns time zones from the backend
 *
 * @returns {string[]} - a list of time zones
 */
async function getTimeZones() {
  const url = '/api/edf2mff/timezones';
  const response = await http.get(url);
  return response.data;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
}));

/**
 * ConvertToMffDialog
 *
 * renders a modal dialog with two drop down lists
 * to select a sensornet and a time zone.
 *
 * @param {Object} props - component's properties
 * @param {bool} props.open - determines if show the modal dialog
 * @param {function} props.onClose - invoked when modal dialog is being closed
 * @param {Object.<string, string>} props.fileProps - edf file properties
 * @returns {React.Component} - returns a form with two drop down lists within Dialog component
 */
const ConvertToMffDialog = ({ open, onClose, fileProps }) => {
  const classes = useStyles();
  const [sensornets, setSensornets] = useState([]);
  const [sensornet, setSensornet] = useState('');
  const [timeZones, setTimeZones] = useState([]);
  const [timeZone, setTimeZone] = useState('');

  useEffect(() => {
    async function getOptions() {
      const sortedSensornets = await getSensornets(fileProps, true);
      const sortedTimeZones = await getTimeZones();
      setSensornets(sortedSensornets);
      setSensornet(sortedSensornets[0]);
      setTimeZones(sortedTimeZones);
      const { tZone } = Intl.DateTimeFormat().resolvedOptions();
      const defaultTimeZone = tZone && sortedTimeZones.includes(tZone) ? tZone : sortedTimeZones[0];
      setTimeZone(defaultTimeZone);
    }
    getOptions();
  }, [fileProps]);

  const handleChangeSensornet = ({ target }) => {
    setSensornet(target.value);
  };

  const handleChangeTimeZone = (event, value) => {
    setTimeZone(value.label);
  };

  const handleCancel = () => {
    onClose('', '');
  };

  const handleOk = () => {
    onClose(sensornet, timeZone);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>Select Sensornet and Time Zone</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <Select
              name="sensornet"
              label="Sensornet"
              value={sensornet}
              options={sensornets}
              onChange={handleChangeSensornet}
            />
            <TimeZones
              timeZones={timeZones}
              timeZone={timeZone}
              onChange={handleChangeTimeZone}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            color="primary"
            variant="contained"
            size="small"
            style={{
              paddingTop: '1px',
              paddingBottom: '1px',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOk}
            color="primary"
            variant="contained"
            size="small"
            style={{
              paddingTop: '1px',
              paddingBottom: '1px',
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ConvertToMffDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fileProps: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ConvertToMffDialog;
