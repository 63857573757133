import React, { Component } from 'react';
import http from '../../services/httpService';
import UploadInterface from './UploadInterface';
import { fileListToArray } from './helpers';

const baseUrl = '/api/upload';

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      filenames: [],
      loaded: 0,
    };
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onClickUpload = this.onClickUpload.bind(this);
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const { files } = evt.target;
    console.log(`files: ${files}`);
    if (this.props.onFilesAdded) {
      const array = fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  /**
   * onDrop.
   *
   * @param {string[]} filenames - filenames of the dropped files
   * @param {DataTransferItem[]} items - DTOs of the dropped files
   *
   * @throws - if disabled = true
   */
  async onDrop(filenames, items) {
    if (this.props.disabled) {
      throw new Error('upload disabled');
    }

    const checkErr = (err) => {
      if (this.getFilesFromDataTransferItems.didShowInfo) return;
      if (err.name !== 'EncodingError') return;
      this.getFilesFromDataTransferItems.didShowInfo = true;
      const infoMsg = `${err.name} occured within datatransfer-files-promise module\n`
        + `Error message: "${err.message}"\n`
        + 'Try serving html over http if currently you are running it from the filesystem.';
      console.warn(infoMsg);
    };

    const readFile = (entry, path = '') => new Promise((resolve, reject) => {
      entry.file(
        (file) => {
          file.filepath = path + file.name;
          resolve(file);
        },
        (err) => {
          checkErr(err);
          reject(err);
        },
      );
    });

    const dirReadEntries = (dirReader, path) => new Promise((resolve, reject) => {
      dirReader.readEntries(
        async (entries) => {
          let files = [];
          for (const entry of entries) {
            const itemFiles = await getFilesFromEntry(entry, path); //eslint-disable-line
            files = files.concat(itemFiles);
          }
          resolve(files);
        },
        (err) => {
          checkErr(err);
          reject(err);
        },
      );
    });

    const readDir = async (entry, path) => {
      const dirReader = entry.createReader();
      const newPath = `${path + entry.name}/`;
      let files = [];
      let newFiles;
      do {
        newFiles = await dirReadEntries(dirReader, newPath);
        files = files.concat(newFiles);
      } while (newFiles.length > 0);
      return files;
    };

    const getFilesFromEntry = async (entry, path = '') => {
      if (entry.isFile) {
        const file = await readFile(entry, path);
        return [file];
      }
      if (entry.isDirectory) {
        const files = await readDir(entry, path);
        return files;
      }
      return null;
      // throw new Error('Entry not isFile and not isDirectory - unable to get files')
    };

    let files = [];
    const entries = [];
    // Pull out all entries before reading them
    for (let i = 0, ii = items.length; i < ii; i += 1) {
      entries.push(items[i].webkitGetAsEntry());
    }

    // Recursively read through all entries
    for (const entry of entries) {
      const newFiles = await getFilesFromEntry(entry);
      files = files.concat(newFiles);
    }
    this.setState({ files, filenames });
  }

  onClickUpload = async () => {
    // Grab some commonly used data from props.
    const { username } = this.props.user;
    const ptid = this.props.patient.medicalID;
    const { fileID } = this.props;

    const data = new FormData();
    this.state.files.forEach((file) => {
      data.append('file', file, file.filepath);
    });
    data.append('creator', username);
    data.append('patientId', ptid);
    data.append('filelist', this.state.filenames.join(':'));

    const uploadTag = `${Date.now()}_${username}`;
    // Post the files.
    await http.post(baseUrl, data, {
      headers: {
        username,
        ptid,
        'upload-tag': uploadTag,
      },
      onUploadProgress: ({ loaded: progress, total }) => {
        this.setState({
          loaded: 100 * (progress / total),
        });
      },
    });

    // console.log(res.statusText);

    // Now that the files have been uploaded, attemp to commit them.
    // We use this opportunity to let the system know "meta"
    // information. For example:
    // - At present, we treat all directories as a "single" entry.
    //
    // These choices can (and likely will) be changed.
    await http.post(
      `${baseUrl}/commit`,
      {
        username: this.props.user.username,
        ptid: this.props.patient.medicalID,
        fileID,
        filelist: this.state.filenames,
      },
      {
        headers: {
          username,
          ptid,
          'upload-tag': uploadTag,
        },
      },
    );

    this.setState({
      loaded: 0,
    });

    this.props.postSubmit();
  };

  render() {
    return (
      <UploadInterface
        onDrop={this.onDrop}
        onUpload={this.onClickUpload}
        uploadProgress={this.state.loaded}
        onFileInput={this.onFilesAdded}
        disabled={this.props.disabled}
      />
    );
  }
}

export default Upload;
