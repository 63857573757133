import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import * as Yup from 'yup';

import { RegisterUser } from '../../models/user';
import { patchUser } from '../../services/users';

/**
 * Displays a dialog used for changing a user's password. Prompts the user to enter and confirm the
 * new password and queries the API to update the password on submission.
 *
 * @param {Object} props
 * @param {string} props.username - The username of the user whose password is being changed
 * @param {boolean} props.open - Whether the dialog should be open or not
 * @param {function} props.onClose - Function to call on dialog close
 * @returns {React.Component}
 */
const ChangeUserPasswordDialog = ({ username, open, onClose }) => {
  const handleSubmit = async (values) => {
    await patchUser(username, { password: values.newPassword });
    onClose();
  };

  const fields = [
    { name: 'newPassword', label: 'New Password' },
    { name: 'confirmNewPassword', label: 'Confirm New Password' },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <DialogContentText>{`Change the password for user "${username}"`}</DialogContentText>
        <Formik
          initialValues={{
            newPassword: '',
            confirmNewPassword: '',
          }}
          validate={async (values) => {
            const errors = {};
            try {
              const passwordSchema = Yup.reach(RegisterUser, 'password').label('New Password');
              await passwordSchema.validate(values.newPassword);
            } catch (err) {
              errors.newPassword = err.errors[0];
            }
            if (values.newPassword !== values.confirmNewPassword) {
              errors.confirmNewPassword = 'Passwords must match';
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              {fields.map(({ name, label }) => (
                <Field
                  key={name}
                  component={TextField}
                  name={name}
                  label={label}
                  required
                  fullWidth
                  margin="normal"
                  type="password"
                  autoComplete="off"
                />
              ))}
              <DialogActions>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={submitForm} disabled={isSubmitting} color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

ChangeUserPasswordDialog.propTypes = {
  username: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangeUserPasswordDialog;
