import http from './httpService';

const baseUrl = '/api/stats';

/**
 * Get disk space usage information
 *
 * @returns {Promise<Object>} Number of bytes free, used, and total for the drive
 */
const getDiskSpace = async () => {
  const { data } = await http.get(`${baseUrl}/diskSpace`);
  return data;
};

export {
  getDiskSpace,
};
