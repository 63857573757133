import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import http from '../../services/httpService';
import UploadInterface from './UploadInterface';
import { fileListToArray } from './helpers';
import { getFiles } from '../../services/experiments';
import { filenameFromUrl, urlFromFilename } from '../../services/files';
import { maybeTruncateString } from '../../services/utils';

/**
 * ExperimentFileUpload component
 *
 * @param {object} props - component properties
 * @param {string} props.username - username of logged in user
 * @param {string} props.experimentId - experiment ID of viewed experiment
 * @param {string} props.patientId - patient ID of viewed experiment
 * @param {boolean} props.disabled - if true, dialog will be disabled
 * @param {function} props.onFilesAdded - invoked when files are added
 * @param {function} props.onUpload - invoked when files start uploading
 * @param {function} props.onSubmit - invoked when files end uploading
 */
const ExperimentFileUpload = (props) => {
  const {
    username,
    experimentId,
    patientId,
    disabled,
    onFilesAdded: customOnFilesAdded,
    onUpload,
    onSubmit,
  } = props;

  const [loaded, setLoaded] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const baseUrl = `/api/experiments/${experimentId}/files`;

  const onFilesAdded = (evt) => {
    if (disabled) return;
    const { files: newFiles } = evt.target;
    console.log(`files: ${newFiles}`);
    if (customOnFilesAdded) {
      const array = fileListToArray(newFiles);
      customOnFilesAdded(array);
    }
  };

  const getFilesFromDataTransferItems = async (dataTransferItems) => {
    const checkErr = (err) => {
      if (getFilesFromDataTransferItems.didShowInfo) return;
      if (err.name !== 'EncodingError') return;
      getFilesFromDataTransferItems.didShowInfo = true;
      const infoMsg = `${err.name} occured within datatransfer-files-promise module\n`
        + `Error message: "${err.message}"\n`
        + 'Try serving html over http if currently you are running it from the filesystem.';
      console.warn(infoMsg);
    };

    const readFile = (entry, filepath = '') => new Promise((resolve, reject) => {
      entry.file(
        (file) => {
          file.filepath = filepath + file.name;
          resolve(file);
        },
        (err) => {
          checkErr(err);
          reject(err);
        },
      );
    });

    const getFilesFromEntry = async (entry, filepath = '') => {
      if (entry.isFile) {
        const entryFile = await readFile(entry, filepath);
        return [entryFile];
      }
      if (entry.isDirectory) {
        const entryFiles = await readDir(entry, filepath); //eslint-disable-line
        return entryFiles;
      }
      throw new Error('Entry not isFile and not isDirectory - unable to get files');
    };

    const dirReadEntries = (dirReader, filepath) => new Promise((resolve, reject) => {
      dirReader.readEntries(
        async (entries) => {
          let readFiles = [];
          for (const entry of entries) {
            const itemFiles = await getFilesFromEntry(entry, filepath);
            readFiles = readFiles.concat(itemFiles);
          }
          resolve(readFiles);
        },
        (err) => {
          checkErr(err);
          reject(err);
        },
      );
    });

    const readDir = async (entry, dirPath) => {
      const dirReader = entry.createReader();
      const newPath = `${dirPath + entry.name}/`;
      let dirFiles = [];
      let newFiles;
      do {
        newFiles = await dirReadEntries(dirReader, newPath);
        dirFiles = dirFiles.concat(newFiles);
      } while (newFiles.length > 0);
      return dirFiles;
    };

    let newFiles = [];
    const entries = [];

    // Pull out all entries before reading them
    for (let i = 0, ii = dataTransferItems.length; i < ii; i += 1) {
      entries.push(dataTransferItems[i].webkitGetAsEntry());
    }

    // Recursively read through all entries
    for (const entry of entries) {
      const entryFiles = await getFilesFromEntry(entry);
      newFiles = newFiles.concat(entryFiles);
    }

    return newFiles;
  };

  const onDrop = async (newFileEntries, items) => {
    if (disabled) return;
    const newFiles = await getFilesFromDataTransferItems(items);
    const files = await getFiles(experimentId, patientId);
    const filenames = files.map(({ url }) => filenameFromUrl(url));
    const newFilenames = newFileEntries.map((file) => file.name);
    const uploadTag = `${Date.now()}_${username}`;
    const longFilenames = newFilenames.filter((name) => {
      const url = urlFromFilename(name, uploadTag, experimentId);
      // TODO: retrieve the max length from the backend
      return url.length > 400;
    });
    if (longFilenames.length > 0) {
      longFilenames.forEach((name) => {
        const caption = maybeTruncateString(name, 25);
        toast.error(`File name "${caption}" too long`);
      });
      return;
    }
    const isDuplicate = (name) => filenames.includes(name);
    const duplicateFilenames = newFilenames.filter(isDuplicate);
    if (duplicateFilenames.length > 0) {
      duplicateFilenames.forEach((name) => {
        const caption = maybeTruncateString(name, 25);
        toast.error(`A file or directory with name "${caption}" `
        + `already exists in participant ${patientId}`);
      });
      return;
    }
    const data = new FormData();
    const filelist = JSON.stringify(newFileEntries);
    data.append('creator', username);
    data.append('patientId', patientId);
    data.append('filelist', filelist);
    newFiles.forEach((file) => {
      data.append('file', file, file.filepath);
    });

    const licenseId = localStorage.getItem('licenseId');
    onUpload();

    try {
      const startTime = Date.now();
      await http.post(baseUrl, data, {
        headers: {
          'upload-tag': uploadTag,
          'x-license-uuid': licenseId,
        },
        onUploadProgress: ({ loaded: progress, total }) => {
          const elapsed = Date.now() - startTime;
          const uploadRate = progress / elapsed;
          const ms = Math.round((total - progress) / uploadRate);
          setTimeLeft(ms);
          setLoaded(100 * (progress / total));
        },
      });
    } catch ({ response }) {
      const message = response?.data?.message
        || 'Unexpected error when uploading file';
      toast.error(message);
    }
    setLoaded(0);

    onSubmit();
  };

  return (
    <UploadInterface
      onDrop={onDrop}
      uploadProgress={loaded}
      onFileInput={onFilesAdded}
      disabled={disabled}
      timeLeft={timeLeft}
      uploadBtnVisible={false}
    />
  );
};

ExperimentFileUpload.propTypes = {
  username: PropTypes.string.isRequired,
  experimentId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onFilesAdded: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ExperimentFileUpload;
