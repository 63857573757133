import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowRightSharp from '@material-ui/icons/ArrowRightSharp';
import RemainingTimeIndicator from '../RemainingTimeIndicator';
import UploadImage from './baseline-cloud_upload-24px.svg';
import './Upload.css';

/**
 * upload-interface component
 *
 * @param {function} onDrop - receives a file-drop event
 * @param {function} onUpload - triggered when "Upload" is clicked
 * @param {boolean} uploadBtnVisible - if true, "Upload" button will be visible
 * @param {number} uploadProgress - indicates a progress bar; in (0, 1)
 * @param {number} onFileInput - triggered on file dialog
 * @param {boolean} disabled - disable file dialog
 * @param {number} timeLeft - estimated time left in milliseconds
 */
function UploadInterface({
  onDrop,
  onUpload,
  uploadBtnVisible,
  uploadProgress,
  onFileInput,
  disabled,
  timeLeft,
}) {
  const [highlight, setHighlight] = useState(false);
  const [displayedFiles, setDisplayedFiles] = useState([]);

  /* highlight dropzone on drag-over */
  const onDragOver = (event) => {
    event.preventDefault();
    setHighlight(true);
  };

  /*
   * un-highlight dropzone on drag-leave
   */
  const onDragLeave = (event) => {
    event.preventDefault();
    setHighlight(false);
  };

  /*
   * un-highlight dropzone and trigger `onDrop()`
   */
  const doOnDrop = async (event) => {
    event.preventDefault();
    setHighlight(false);
    // gather files from event beforehand; `onDrop()` destroys these.
    if (!event.dataTransfer) {
      return;
    }
    const { items } = event.dataTransfer;
    if (!items) {
      return;
    } else if (uploadProgress > 0) {
      toast.error('Upload already in progress');
      return;
    }
    const fileEntries = [];
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      const asFile = item.getAsFile();
      const asEntry = item.webkitGetAsEntry();
      if (asFile === null || asEntry === null) {
        toast.error('Unable to access file(s). Verify that the file(s) exist on local filesystem '
        + 'and are not in an unextracted archive');
        return;
      }
      const { name, isDirectory } = asEntry;
      fileEntries.push({ name, isDirectory });
    }
    setDisplayedFiles(fileEntries.map(({ name }) => name));
    try {
      await onDrop(fileEntries, items);
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const fileInputRef = React.createRef();
  /* eslint-disable */
  const openFileDialog = () => {
    // Fix This!
    // This functionality is disabled at the moment!
    return;
    if (this.props.disabled) return;
    fileInputRef.current.click();
  }
  /* eslint-enable */

  return (
    <div className="DropzoneWrapper">
      <div //eslint-disable-line
        className={`Dropzone ${highlight ? 'Highlight' : ''}`}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={doOnDrop}
        onClick={openFileDialog}
        style={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <input
          type="file"
          className="FileInput"
          ref={fileInputRef}
          multiple
          onChange={onFileInput}
        />
        <img alt="upload" className="Icon" src={UploadImage} />
        <span>Upload Files</span>
      </div>
      <div className="FileWrapperlist">
        <div className="Filelist">
          {displayedFiles.length === 0 && (
            <div data-cy="file-upload-label">Drop Files in Dropzone to start Upload</div>
          )}
          {displayedFiles.length !== 0 && (
            <>
              <List dense disablePadding>
                {displayedFiles.map((file) => {
                  let displayString = '';
                  if (file.length > 30) {
                    displayString = `${file.substring(0, 30)}...`;
                  } else {
                    displayString = file;
                  }
                  return (
                    <ListItem>
                      <ListItemIcon>
                        <ArrowRightSharp />
                      </ListItemIcon>
                      <ListItemText primary={displayString} />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
        </div>
        {uploadProgress !== 0 && (
          <p
            style={{
              color: 'red',
              width: '300px',
              'font-size': '20px',
              'text-align': 'center',
            }}
          >
            Closing or refreshing the page will abort the upload
          </p>
        )}
        <div>
          {uploadBtnVisible && (
            <Button
              variant="contained"
              color="primary"
              onClick={onUpload}
              data-cy="upload-button"
            >
              Upload
            </Button>
          )}
        </div>
        {uploadProgress !== 0 && (
          <>
            <RemainingTimeIndicator value={timeLeft} />
            <div className="Progress">
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

UploadInterface.propTypes = {
  timeLeft: PropTypes.number.isRequired,
  onDrop: PropTypes.func.isRequired,
  onUpload: PropTypes.func,
  uploadBtnVisible: PropTypes.bool,
  uploadProgress: PropTypes.number.isRequired,
  onFileInput: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

UploadInterface.defaultProps = {
  onUpload: () => {},
  uploadBtnVisible: true,
};

export default UploadInterface;
