import React, { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { getCurrentUser } from '../services/authService';
import axios from '../services/httpService';
import { getToken } from '../services/notebooks';

const useStyles = makeStyles(() => ({
  iframe: {
    width: '-webkit-fill-available',
    height: '85vh',
  },
}));

/**
 * Component for route '/notebooks'
 *
 * Starts the notebook server in an iframe. Redirects to login page if not
 * logged in.
 */
function Notebooks() {
  const classes = useStyles();
  const user = getCurrentUser();
  const [running, setRunning] = useState(false);
  const [sourceUrl, setSourceUrl] = useState('');

  const startNotebookServer = useCallback(async () => {
    const token = await getToken();
    setSourceUrl(`/api/jupyter/${user.username}/proc/?token=${token}`);
    const { status } = await axios.post('/api/jupyter/start');
    if (status === 201) {
      const waitTime = 3000; // ms
      toast.info('Starting Jupyter Notebook Server..', {
        position: 'top-center',
        autoClose: waitTime,
      });
      setTimeout(() => setRunning(true), waitTime);
    } else {
      setRunning(true);
    }
  }, [user.username]);

  useEffect(() => { startNotebookServer(); }, [startNotebookServer]);

  if (!user) {
    return (
      <Redirect to="/login" />
    );
  }

  if (running) {
    return (
      <>
        <iframe className={classes.iframe} src={sourceUrl} title="notebooks-iframe" />
      </>
    );
  } return (
    <p>Starting server..</p>
  );
}

export default Notebooks;
