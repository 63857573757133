/**
 * @fileOverview The logged in user profile.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React from 'react';

// FLOW
import noUser from '../NoUser.png';

const Profile = ({ user }) => {
  let userInfo = user;
  if (userInfo !== null) {
    console.log(userInfo);
  } else {
    userInfo = {
      firstname: 'none',
      lastname: 'none',
      username: 'none',
      role: 'none',
      userType: 'none',
    };
    console.log('Figure out why this is rendering twice!');
  }
  return (
    <div className="container">
      <br />
      <div className="user-profile">
        <div className="box one" />
        <div className="shadow two p-3 mb-3 bg-white rounded">
          <div className="card" style={{ width: '18rem' }}>
            <img className="card-img-top" src={noUser} alt="Card cap" />
            <div className="card-body">
              <p className="card-text">
                <ul>
                  <li>
                    <b>Name:</b>
                    {' '}
                    {`${userInfo.firstname} ${userInfo.lastname}`}
                  </li>
                  <li>
                    {' '}
                    <b>Username: </b>
                    {userInfo.username}
                  </li>
                  <li>
                    {' '}
                    <b>Role: </b>
                    {userInfo.role}
                  </li>
                  <li>
                    {' '}
                    <b>Type: </b>
                    {userInfo.userType}
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="box one" />
      </div>
    </div>
  );
};

export default Profile;
