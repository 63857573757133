import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  Badge,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

/**
 * ListItemLink
 *
 * @param {Object} props - component properties
 * @param {string} props.title - list item's title
 * @param {string} props.link - list item's link
 * @param {React.Component} props.Icon - Icon to be displayed along with the list item
 * @param {number} props.badgeVisible - if true, badge will be visible
 * @param {number} props.badgeVariant - badge variant to use
 * @param {number} props.badgeContent - badge content to be displayed on the list item
 * @param {function} props.onClick - invoked when item is clicked
 * @param {boolean} props.visible - if true, the list item will be visible
 * @returns {React.Component} - returns a list item with a navigation link
 */
function ListItemLink({
  title,
  link,
  Icon,
  badgeVisible,
  badgeVariant,
  badgeContent,
  onClick,
  visible,
}) {
  return visible
  && (
    <NavLink
      color="inherit"
      to={link}
      activeStyle={{
        color: 'blue',
      }}
      style={{
        textDecoration: 'none',
        color: 'black',
      }}
    >
      <ListItem
        button
        key={title}
        onClick={onClick}
      >
        <ListItemIcon>
          <Badge
            color="secondary"
            variant={badgeVariant}
            invisible={!badgeVisible || (badgeVariant === 'standard' && !badgeContent)}
            badgeContent={badgeContent}
          >
            <Icon />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    </NavLink>
  );
}

ListItemLink.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  Icon: PropTypes.element.isRequired,
  badgeVisible: PropTypes.bool.isRequired,
  badgeVariant: PropTypes.string,
  badgeContent: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

ListItemLink.defaultProps = {
  badgeVariant: 'standard',
};

export default ListItemLink;
