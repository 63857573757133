/**
 * @fileOverview Displays a list of users.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */
import MaterialTable, { MTableAction } from '@material-table/core';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { getUsers } from '../../services/users';
import RegisterUser from './RegisterUser';
import DeleteUserDialog from './DeleteUserDialog';
import ChangeUserPasswordDialog from './ChangeUserPasswordDialog';

const AddUserButton = styled(Button)({
  margin: '10px',
});

const Users = ({ user }) => {
  const [data, setData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  // configuration for the confirm dialog spawned when deleting users
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [usernameToDelete, setUsernameToDelete] = useState('');

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
  const [changePasswordUsername, setChangePasswordUsername] = useState('');

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const addUserText = 'Register user';

  /**
   * Transform a user object for use in a table
   *
   * @param {Object} userObj - The user object
   * @returns {Object} The transformed user object
   */
  const transformUser = (userObj) => ({
    name: `${userObj.firstname} ${userObj.lastname}`,
    ...(userObj),
  });

  const fetchData = useCallback(async () => {
    let newUsers = await getUsers();
    newUsers = newUsers.map(transformUser);
    setData(newUsers);
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteUserDialogClose = async () => {
    await fetchData();
    setConfirmDeleteOpen(false);
  };

  const columns = [
    { title: 'Username', field: 'username' },
    { title: 'Name', field: 'name' },
    { title: 'Role', field: 'role' },
  ];

  const actions = [
    {
      icon: '',
      tooltip: addUserText,
      isFreeAction: true,
      onClick: handleDialogOpen,
    },
  ];
  if (user.role === 'Admin') {
    actions.push((rowData) => ({
      icon: 'delete_outline',
      disabled: rowData.role !== 'Researcher',
      tooltip: 'Delete a user account',
      // Open the modal when clicked
      onClick: (event, row) => {
        setUsernameToDelete(row.username);
        setConfirmDeleteOpen(true);
      },
    }));
    actions.push((rowData) => ({
      icon: 'lock',
      disabled: rowData.username === 'admin',
      tooltip: 'Change password',
      onClick: (event, row) => {
        setChangePasswordUsername(row.username);
        setChangePasswordDialogOpen(true);
      },
    }));
  }

  return (
    <>
      <MaterialTable
        title="Users"
        columns={columns}
        data={data}
        actions={actions}
        options={{
          paging: false,
        }}
        components={{
          Action: (componentProps) => {
            if (componentProps.action.tooltip === addUserText) {
              return (
                <AddUserButton
                  color="primary"
                  variant="contained"
                  onClick={(event) => componentProps.action.onClick(event, componentProps.data)}
                >
                  {addUserText}
                </AddUserButton>
              );
            }
            // Use the regular action component for inline actions
            return (
              <MTableAction
                {...componentProps} // eslint-disable-line react/jsx-props-no-spreading
              />
            );
          },
        }}
        localization={{
          header: {
            actions: '',
          },
        }}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullwidth
      >
        <DialogTitle>{addUserText}</DialogTitle>
        <DialogContent>
          <RegisterUser
            user={user}
            handleClose={handleDialogClose}
            handlePostSubmit={async () => {
              await fetchData();
              handleDialogClose();
            }}
          />
        </DialogContent>
      </Dialog>
      <DeleteUserDialog
        username={usernameToDelete}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onClose={handleDeleteUserDialogClose}
      />
      <ChangeUserPasswordDialog
        open={changePasswordDialogOpen}
        username={changePasswordUsername}
        onClose={() => setChangePasswordDialogOpen(false)}
      />
    </>
  );
};

Users.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

export default Users;
