/**
 * convert a file list to an array
 *
 * @param {object} list - file-list like object
 * @returns {array} - file-list elements in an Array
 */
function fileListToArray(list) {
  return Array.from({ length: list.length }, (_, i) => list.item(i));
}

export {
  fileListToArray,
};
