import { Paper, Switch, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import React from 'react';
import PropTypes from 'prop-types';

import { startConnection, stopConnection } from '../services/ssh';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '16px',
    marginBottom: theme.spacing(1),
  },
}));

const GreenSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.common.white,
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
}))(Switch);

/**
 * RemoteAccess
 *
 * @param {function} enabled - if true, SSH access is enabled
 * @param {function} onChange - invoked when enabling/disabling SSH access
 * @returns {React.Component} - returns a switch element to toggle SSH access
 */
const RemoteAccess = ({ enabled, onChange }) => {
  const classes = useStyles();

  const handleChange = ({ target }) => {
    const { checked } = target;
    if (checked) {
      startConnection();
    } else {
      stopConnection();
    }
    onChange(checked);
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Remote Access [SSH] Controls</Typography>
      <GreenSwitch
        checked={enabled}
        onChange={handleChange}
      />
      <Typography
        display="inline"
        variant="h7"
      >
        {enabled ? 'Enabled' : 'Disabled'}
      </Typography>
    </Paper>
  );
};

RemoteAccess.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

RemoteAccess.defaultProps = {
  onChange: () => {},
};

export default RemoteAccess;
