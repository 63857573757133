/**
 * returns an object as a string
 *
 * @param {Object} obj - object to be converted into a string
 * @param {Object} separator - the separator to be used to
 * separate each pair of adjacent elements.
 * @returns {string} - stringified object
 */
function objToString(obj, separator) {
  const entries = Object.entries(obj).map(([key, value]) => `${key}: (${value})`);
  return entries.join(separator);
}

/**
 * returns a new string up to `maxChars` or the original
 * string if its lenght is less than `maxChars`.
 *
 * @param {string} string - string that may be truncated
 * @param {number} maxChars - max. number of characters of the returned string
 * @returns {string} a new or the original string
 */
function maybeTruncateString(string, maxChars) {
  const truncate = maxChars < string.length;
  return truncate ? `${string.substring(0, maxChars)}\u2026` : string;
}

export {
  objToString,
  maybeTruncateString,
};
