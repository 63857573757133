import { Formik, Form, Field } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  Add as AddIcon,
  RemoveCircleOutline as RemoveIcon,
} from '@material-ui/icons';
import MaterialTable from '@material-table/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import {
  getExperimentCollaborators,
  addExperimentCollaborators,
  deleteExperimentCollaborator,
} from '../services/experiments';
import { getUsers } from '../services/users';

/**
 * ExperimentCollaborators component
 *
 * @param {object} props - jsx property object
 * @param {string} props.username - username of logged in user
 * @param {string} props.experimentId - experimentId of the viewed experiment
 * @param {boolean} props.allowModify - if true enables the "add collaborators" action
 */
const ExperimentCollaborators = (props) => {
  const { username, experimentId, allowModify } = props;
  const [collaborators, setCollaborators] = useState([]);
  const [availableCollaborators, setAvailableCollaborators] = useState([]);

  const fetchCollaborators = useCallback(async () => {
    const newCollaborators = await getExperimentCollaborators(experimentId);
    setCollaborators(newCollaborators);
  }, [experimentId]);

  useEffect(() => {
    fetchCollaborators();
  }, [fetchCollaborators]);

  const fetchAvailableCollaborators = useCallback(async () => {
    const users = await getUsers();
    setAvailableCollaborators(users.filter((user) => (
      !collaborators.some(((collaborator) => collaborator.username === user.username))
      && user.username !== username
      // TODO: Remove Clinician once no more users exist with this role
      && (user.role === 'Researcher' || user.role === 'Clinician')
    )));
  }, [username, collaborators]);

  useEffect(() => {
    fetchAvailableCollaborators();
  }, [fetchAvailableCollaborators]);

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleSubmit = async ({ collaborators: newCollaborators }) => {
    await addExperimentCollaborators(experimentId, newCollaborators.map((item) => item.username));
    fetchCollaborators();
    handleModalClose();
  };

  const columns = [
    { title: 'Name', render: (rowData) => `${rowData.firstname} ${rowData.lastname}` },
    { title: 'Username', field: 'username' },
    { title: 'Role', field: 'role' },
  ];

  return (
    <>
      <br />
      <Tooltip title={allowModify ? '' : 'Not authorized to add collaborators'}>
        <span>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleModalOpen}
            disabled={!allowModify}
          >
            Add Collaborators
          </Button>
        </span>
      </Tooltip>
      <br />
      <br />
      <MaterialTable
        title="Experiment Collaborators"
        columns={columns}
        data={collaborators}
        editable={{
          isDeletable: () => allowModify,
          onRowDelete: async (oldData) => {
            await deleteExperimentCollaborator(experimentId, oldData.username);
            await fetchCollaborators();
          },
        }}
        icons={{
          Delete: RemoveIcon,
        }}
        localization={{
          body: {
            deleteTooltip: 'Remove',
            editRow: {
              deleteText: 'Are you sure you want to remove?',
            },
          },
        }}
        options={{
          paging: false,
          maxBodyHeight: '60vh',
          headerStyle: { position: 'sticky', top: 0 },
        }}
      />
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        fullWidth
      >
        <DialogTitle>Add new collaborators</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ collaborators: [] }}
            onSubmit={handleSubmit}
          >
            {({ submitForm }) => (
              <Form>
                <Field
                  component={Autocomplete}
                  multiple
                  filterSelectedOptions
                  name="collaborators"
                  label="Collaborators"
                  fullWidth
                  margin="normal"
                  options={availableCollaborators}
                  getOptionLabel={(option) => (
                    `${option.firstname} ${option.lastname} (${option.role})`
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params} // eslint-disable-line react/jsx-props-no-spreading
                      label="Collaborators"
                      variant="outlined"
                    />
                  )}
                />
                <DialogActions>
                  <Button onClick={handleModalClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={submitForm} color="primary">
                    Add
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

ExperimentCollaborators.propTypes = {
  username: PropTypes.string.isRequired,
  experimentId: PropTypes.string.isRequired,
  allowModify: PropTypes.bool.isRequired,
};

export default ExperimentCollaborators;
