import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { getFiles } from '../../services/experiments';
import { filenameFromUrl } from '../../services/files';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

/**
 * EditOutputFilenameDialog
 *
 * renders a modal dialog to change the name of the output mff file
 *
 * @param {Object} props - component's properties
 * @param {function} props.open - invoked when dialog is opened
 * @param {string} props.filename - name of input the mff file
 * @param {string} props.patientId - a patient id
 * @param {string} props.experimentId - an experiment id
 * @param {string} props.settingsName - name of selected settings
 * @param {function} props.onClose - invoked when dialog is closed
 * @returns {React.Component} - returns a modal dialog containing a textfield component
 */
const EditOutputFilenameDialog = ({
  open,
  filename,
  patientId,
  experimentId,
  settingsName,
  onClose,
}) => {
  const classes = useStyles();
  const [name, setName] = useState(`${filename.split('.')[0]}_avg`);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const handleEnter = () => {
    setName(`${filename.split('.')[0]}_avg_${settingsName}`);
    setError(false);
  };

  const handleChange = ({ target }) => {
    const { value } = target;
    setName(value);
    setError(false);
    setHelperText('');
  };

  const handleCancel = () => {
    onClose('');
  };

  const handleOk = async () => {
    try {
      const files = await getFiles(experimentId, patientId);
      const existingFilenames = files.map(({ url }) => filenameFromUrl(url));
      let errorMessage = '';
      if (!name) {
        errorMessage = 'Empty string is not a valid name';
      } else if (existingFilenames.includes(`${name}.mff`)) {
        errorMessage = 'Filename already exists';
      }
      setError(!!errorMessage);
      setHelperText(errorMessage);
      if (!errorMessage) {
        onClose(name);
      }
    } catch ({ response }) {
      toast.error(response?.data?.message || 'Unexpected error ocurred');
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onEnter={handleEnter} onClose={handleCancel} fullWidth maxWidth="sm">
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={1}
            justify="center"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={3}>
                <Typography variant="subtitle1" gutterBottom>
                  Output MFF name
                </Typography>
                <Grid container spacing={12}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="file-name"
                      label="Name"
                      value={name}
                      error={error}
                      helperText={helperText}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">.mff</InputAdornment>,
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            color="primary"
            variant="contained"
            size="small"
            style={{
              paddingTop: '1px',
              paddingBottom: '1px',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOk}
            color="primary"
            variant="contained"
            size="small"
            style={{
              paddingTop: '1px',
              paddingBottom: '1px',
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EditOutputFilenameDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  filename: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  experimentId: PropTypes.string.isRequired,
  settingsName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditOutputFilenameDialog;
