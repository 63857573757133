/**
 * @fileOverview Main application component.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

import { detect } from 'detect-browser';
import { Alert } from '@material-ui/lab';
import React, { useCallback, useState, useEffect } from 'react';
import {
  Route,
  Switch,
  Link as RouterLink,
  withRouter,
  useHistory,
} from 'react-router-dom';
import {
  AppBar,
  Box,
  Chip,
  Link,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Button,
  Divider,
} from '@material-ui/core';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ActionRecords from './components/ActionRecords';
import SvgIcon from './icons';
import Devices from './components/Devices';
import ActiveWorkflows from './components/ActiveWorkflows';
import ErrorReports from './components/ErrorReports';
import Licenses from './components/Licenses';
import Notebooks from './components/Notebooks';
import Patients from './components/Patients';
import Users from './components/Users';
import WorkflowHistory from './components/WorkflowHistory';
import ListItemLink from './components/common/ListItemLink';
import RoleRestricted from './components/common/RoleRestricted';
import ProtectedRoute from './components/common/protectedRoute';
import ExperimentsRoute from './components/ExperimentsRoute';
import RemoteAccessRoute from './components/RemoteAccessRoute';
import Home from './components/Home';
import Login from './components/Login';
import About from './components/About';
import Logout from './components/logout';
import Profile from './components/profile';
import DiskSpace from './components/DiskSpace';
import { getCurrentUser, getJWT, getFreeFlowLicense } from './services/authService';
import packageInfo from '../package.json';
import { colors } from './colorscheme';

import { fetchWorkflowCounts } from './services/workflows';
import License from './services/license';
import LicenseSocket from './services/license_socket';
import { isEnabled } from './services/ssh';

const FINISHED_WORKFLOWS_VIEW = '/workflowhistory';
const optionalFeatures = ['Participants', 'Devices', 'Notebooks'];
const drawerWidth = 240;

// icon size of the side-bar menu in pts
const sidebarIconSize = 35;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: colors.AppBar,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  main: {
    backgroundColor: colors.Background,
    flexGrow: 1,
    height: '100vh',
  },
  content: {
    backgroundColor: colors.Background,
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  leftNavBarItem: {
    marginRight: theme.spacing(2),
  },
  belLogoItem: {
    height: 45,
    marginInline: 'auto',
    marginBottom: '10px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar,
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.Button,
      light: '#73e8ff',
      dark: '#0086c3',
    },
  },
});

function ButtonAppBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const user = getCurrentUser();
  const browser = detect();
  const [update, setUpdate] = useState(true);
  const [remote, setRemote] = useState(false);
  const [workflowCounts, setWorkflowCounts] = useState({ active: 0, finished: 0 });
  const [currentView, setCurrentView] = useState('/experiments');
  const [features, setFeatures] = useState(License.defaults());
  const [licenseSocket, setLicenseSocket] = useState(null);
  const { username } = user || {};

  /**
   * Fetch licensed features
   *
   * @function getFeatures
   */
  const getFeatures = useCallback(async () => {
    if (username) {
      const license = new License();
      await license.update();
      setFeatures(license.features);
    }
  }, [username]);

  useEffect(() => { getFeatures(); }, [getFeatures]);

  const onLicenseSocketClose = (event) => {
    console.log(`license closed with code: ${event.code} and reason: "${event.reason}"`);
    history.push('/logout', { message: event.reason });
    setLicenseSocket(null);
    localStorage.removeItem('licenseId');
  };

  /**
   * Acquires a license socket. If a license token has been saved to local storage, use it.
   * Otherwise, obtain a new free FLOW license and save it to local storage first.
   */
  const acquireLicense = useCallback(async () => {
    let licenseId = localStorage.getItem('licenseId');
    if (!licenseId) {
      const license = await getFreeFlowLicense();
      licenseId = license.token;
      localStorage.setItem('licenseId', licenseId);
    }
    const newLicenseSocket = new LicenseSocket();
    await newLicenseSocket.connect(licenseId, getJWT(), onLicenseSocketClose);
    setLicenseSocket(newLicenseSocket);
  }, []);

  useEffect(() => {
    if (username && !licenseSocket) {
      acquireLicense();
    }
  }, [username, licenseSocket, acquireLicense]);

  useEffect(() => {
    if (licenseSocket) {
      const handleClick = () => licenseSocket.resetTimeout();
      window.addEventListener('click', handleClick);
      return () => window.removeEventListener('click', handleClick);
    }
    return undefined;
  }, [licenseSocket]);

  const handleListItemClick = async (view) => {
    setCurrentView(view);
    if (view === FINISHED_WORKFLOWS_VIEW) {
      setUpdate(true);
    }
  };

  useEffect(() => {
    const updateWorkflowCounts = async () => {
      if (username) {
        const isFinishedWorkflowsView = currentView === FINISHED_WORKFLOWS_VIEW;
        const counts = await fetchWorkflowCounts(username, isFinishedWorkflowsView);
        setWorkflowCounts(counts);
      }
    };

    if (update) {
      updateWorkflowCounts().then(() => setUpdate(false));
    }
  }, [currentView, update, username]);

  useEffect(() => {
    if (username) {
      isEnabled().then((enabled) => setRemote(enabled));
    }
  }, [username]);

  /**
   * @typedef {Object} View
   * @property {string} title
   * @property {string} link
   * @property {React.ReactNode} Icon
   * @property {string[]} [allowedRoles]
   */

  /** @type {View[]} */
  const views = [{
    title: 'Experiments',
    link: '/experiments',
    Icon: SvgIcon({ name: 'Experiments', width: sidebarIconSize }),
  }];
  if (features.participants) {
    views.push({
      title: 'Participants',
      link: '/participants/0/0',
      Icon: SvgIcon({ name: 'Participants', width: sidebarIconSize }),
      allowedRoles: ['Admin', 'Lab Manager'],
    });
  }
  if (features.devices) {
    views.push({
      title: 'Devices',
      link: '/devices',
      Icon: SvgIcon({ name: 'Devices', width: sidebarIconSize }),
    });
  }
  views.push({
    title: 'Active Workflows',
    link: '/activeworkflows',
    Icon: SvgIcon({ name: 'ActiveWorkflows', width: sidebarIconSize }),
  });
  views.push({
    title: 'Workflow History',
    link: '/workflowhistory',
    Icon: SvgIcon({ name: 'WorkflowHistory', width: sidebarIconSize }),
  });
  views.push({
    title: 'Users',
    link: '/users',
    Icon: SvgIcon({ name: 'Users', width: sidebarIconSize }),
    allowedRoles: ['Admin', 'Lab Manager'],
  });
  views.push({
    title: 'Action Records',
    link: '/actionRecords',
    Icon: SvgIcon({ name: 'ActionRecords', width: sidebarIconSize }),
    allowedRoles: ['Admin'],
  });
  views.push({
    title: 'Error Reports',
    link: '/errorReports',
    Icon: SvgIcon({ name: 'ErrorReports', width: sidebarIconSize }),
    allowedRoles: ['Admin'],
  });
  views.push({
    title: 'Licenses',
    link: '/licenses',
    Icon: SvgIcon({ name: 'Licenses', width: sidebarIconSize }),
    allowedRoles: ['Admin'],
  });
  if (features.notebooks) {
    views.push({
      title: 'Notebooks',
      link: '/notebooks',
      Icon: SvgIcon({ name: 'Notebooks', width: sidebarIconSize }),
    });
  }
  views.push({
    title: 'Remote Access',
    link: '/remoteAccess',
    Icon: SvgIcon({ name: 'RemoteAccess', width: sidebarIconSize }),
    allowedRoles: ['Admin'],
  });
  views.push({
    title: 'About',
    link: '/about',
    Icon: SvgIcon({
      name: 'Info',
      width: sidebarIconSize,
      filter: `brightness(0%) invert(26%) sepia(72%)
      saturate(7255%) hue-rotate(178deg) contrast(98%)`,
    }),
  });

  const FlowIcon = SvgIcon({
    name: 'FLOW', width: 100, height: 50, className: classes.leftNavBarItem,
  });
  const BelIcon = SvgIcon({
    name: 'BEL', className: classes.belLogoItem,
  });
  const UserIcon = SvgIcon({ name: 'UserProfile', width: 30 });

  switch (browser && browser.name) {
    case 'chrome':
      return (
        <div className={classes.root}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {props.location.pathname !== '/login'
            && (
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <Box display="flex" flexGrow={1} alignItems="center">
                  <FlowIcon />
                  <Chip
                    label={`v ${packageInfo.version}`}
                    className={classes.leftNavBarItem}
                    size="small"
                  />
                </Box>
                {remote && (
                <div style={{ width: '100%', display: 'flex', 'justify-content': 'center' }}>
                  <Alert severity="info">FLOW currently under maintenance</Alert>
                </div>
                )}
                {user && (
                <div style={{ width: '500px', display: 'flex', 'justify-content': 'right' }}>
                  <UserIcon />
                  <div>
                    <p
                      style={{ margin: '0px 10px', 'text-align': 'center' }}
                    >
                      <b>{user && `${user.firstname} ${user.lastname}`.toUpperCase()}</b>
                    </p>
                    <p
                      style={{ margin: '0px 10px', 'text-align': 'center' }}
                    >
                      {user && `${user.role}`}
                    </p>
                  </div>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to="/logout"
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      data-cy="log-out"
                      onClick={() => licenseSocket.socket.close(1000, '')}
                    >
                      LOG OUT
                    </Button>
                  </Link>
                </div>
                )}
              </Toolbar>
            </AppBar>
            )}

            {/*
            Drawer

            This is displayed depending on whether a user is present. The content of
            each "drawer" is not actually dependent on the drawer object, but is
            determined below in the content section.
             */}

            {user && (
              <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.toolbar} />
                <div style={{ flexGrow: 1 }}>
                  <List>
                    {views.map(({
                      allowedRoles,
                      link,
                      title,
                      Icon,
                    }) => (
                      <RoleRestricted user={user} allowedRoles={allowedRoles}>
                        <ListItemLink
                          title={title}
                          link={link}
                          Icon={Icon}
                          badgeVisible={link === '/remoteAccess' ? remote : true}
                          badgeVariant={link === '/remoteAccess' ? 'dot' : 'standard'}
                          badgeContent={
                            ['/activeworkflows', '/workflowhistory'].includes(link)
                              ? workflowCounts[link === '/activeworkflows' ? 'active' : 'finished'] : 0
                          }
                          onClick={() => handleListItemClick(link)}
                          visible={optionalFeatures.includes(title)
                            ? features[title.toLowerCase()] : true}
                        />
                      </RoleRestricted>
                    ))}
                  </List>
                  <Divider />
                  {user && <DiskSpace />}
                </div>
                <BelIcon />
              </Drawer>
            )}

            {/*
            Content

            We are using the Switch from react-router-dom to determine what to display
            in the content for each selected drawer.

            If the drawer is not open, the routes are determined from the selection on the
            AppBar.
             */}
            <main className={classes.main}>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <div className={classes.content}>
                  <div className={classes.toolbar} />
                  <div className="content">
                    <ProtectedRoute path="/profile" component={Profile} />
                    <ProtectedRoute path="/users" component={Users} />
                    <ProtectedRoute
                      path="/activeworkflows"
                      component={ActiveWorkflows}
                    />
                    <ProtectedRoute
                      path="/workflowhistory"
                      component={WorkflowHistory}
                    />
                    <ProtectedRoute
                      path="/participants/:medicalID/:fileID"
                      component={Patients}
                    />
                    <ExperimentsRoute
                      path={['/experiments/:experimentId/:action', '/experiments']}
                      onWorkflowUpdate={() => setUpdate(true)}
                    />
                    <ProtectedRoute
                      path="/devices"
                      component={Devices}
                    />
                    <ProtectedRoute
                      path="/errorReports"
                      component={ErrorReports}
                    />
                    <ProtectedRoute
                      path="/licenses"
                      render={() => <Licenses licenseSocket={licenseSocket} />}
                    />
                    <RemoteAccessRoute
                      path="/remoteAccess"
                      enabled={remote}
                      onChange={setRemote}
                    />
                    <ProtectedRoute
                      path="/notebooks"
                      component={Notebooks}
                    />
                    <ProtectedRoute
                      path="/actionRecords"
                      component={ActionRecords}
                    />
                    <ProtectedRoute
                      path="/about"
                      component={About}
                    />
                    <Route path="/" exact component={Home} />
                  </div>
                </div>
              </Switch>
            </main>
          </ThemeProvider>
        </div>
      );
    case 'edge':
    case 'firefox':
      return `Unsupported browser(${browser.name}): Please use Chrome!`;
    default:
      return 'Unsupported browser(unknown): Please use Chrome!';
  }
}

export default withRouter(ButtonAppBar);
