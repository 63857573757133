import http from './httpService';

const baseUrl = '/api/errorReports';

const getErrorReports = async () => {
  const { data: errorReports } = await http.get(baseUrl);
  return errorReports;
};

export {
  getErrorReports,
};
