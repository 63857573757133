import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  InputAdornment,
  Checkbox,
  Grid,
  FormControlLabel,
} from '@material-ui/core';

/**
 * FilterSettings
 *
 * renders controls to specify filter settings in a given ERP workflow run.
 *
 * @param {Object} props - component's properties.
 * @param {Object.<string, boolean>} props.enabled - indicates enabled filters
 * @param {Object.<string, number>} props.cutoff - indicates highpass and lowpass cutoff freqs.
 * @param {function} props.order - filter order.
 * @param {function} props.onChangeEnabledFilters - invoked when enabled filters are changed.
 * @param {function} props.onChangeCutoffFreq - invoked when filter cutoff freqs are changed.
 * @param {function} props.onChangeFilterOrder - invoked when filter order is changed.
 * @returns {React.Component} - returns a couple of chekboxes and three text fields to set
 * filters cutoff freqs and filter order.
 */
const FilterSettings = ({
  enabled,
  cutoff,
  order,
  onChangeEnabledFilters,
  onChangeCutoffFreq,
  onChangeFilterOrder,
}) => {
  const handleSetEnabledFilters = (value) => {
    onChangeEnabledFilters({ ...enabled, ...value });
  };

  const handleChangeCutoffFreq = (key, value) => {
    onChangeCutoffFreq({ ...cutoff, [key]: value });
  };

  const handleFinishChangeCutoffFreq = (key, value) => {
    const isValidNumber = !Number.isNaN(value);
    const rounded = isValidNumber ? parseFloat(value.toFixed(1)) : 0.1;
    const positiveValue = rounded < 0.1 ? 0.1 : rounded;
    onChangeCutoffFreq({ ...cutoff, [key]: positiveValue });
  };

  const handleChangeFilterOrder = (value) => {
    onChangeFilterOrder(value);
  };

  const handleFinishChangeFilterOrder = (value) => {
    const isValidNumber = !Number.isNaN(value);
    const rounded = isValidNumber ? Math.round(value) : 1;
    onChangeFilterOrder(rounded < 1 ? 1 : rounded);
  };

  return (
    <Grid
      container
      spacing={1}
      justify="space-evenly"
      alignItems="center"
    >
      <Grid item xs={5}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={enabled.highpass}
              onChange={({ target }) => handleSetEnabledFilters({ highpass: target.checked })}
              name="highpass"
            />
          )}
          label=""
        />
        <TextField
          id="filter-highpass"
          disabled={!enabled.highpass}
          label="Highpass filter"
          type="number"
          style={{ width: '70%' }}
          value={cutoff.highpass}
          onChange={({ target }) => handleChangeCutoffFreq('highpass', target.valueAsNumber)}
          onBlur={({ target }) => handleFinishChangeCutoffFreq('highpass', target.valueAsNumber)}
          onKeyPress={({ target, key }) => {
            if (key === 'Enter') {
              handleFinishChangeCutoffFreq('highpass', target.valueAsNumber);
            }
          }}
          InputProps={{
            inputProps: { min: 0.1, step: 0.1 },
            endAdornment: <InputAdornment position="end">Hz</InputAdornment>,
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={5}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={enabled.lowpass}
              onChange={({ target }) => handleSetEnabledFilters({ lowpass: target.checked })}
              name="lowpass"
            />
          )}
          label=""
        />
        <TextField
          id="filter-lowpass"
          disabled={!enabled.lowpass}
          label="Lowpass filter"
          type="number"
          style={{ width: '70%' }}
          value={cutoff.lowpass}
          onChange={({ target }) => handleChangeCutoffFreq('lowpass', target.valueAsNumber)}
          onBlur={({ target }) => handleFinishChangeCutoffFreq('lowpass', target.valueAsNumber)}
          onKeyPress={({ target, key }) => {
            if (key === 'Enter') {
              handleFinishChangeCutoffFreq('lowpass', target.valueAsNumber);
            }
          }}
          InputProps={{
            inputProps: { min: 0.1, step: 0.1 },
            endAdornment: <InputAdornment position="end">Hz</InputAdornment>,
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="filter-order"
          disabled={!enabled.lowpass && !enabled.highpass}
          label="Filter order"
          type="number"
          value={order}
          onChange={({ target }) => handleChangeFilterOrder(target.valueAsNumber)}
          onBlur={({ target }) => handleFinishChangeFilterOrder(target.valueAsNumber)}
          onKeyPress={({ target, key }) => {
            if (key === 'Enter') {
              handleFinishChangeFilterOrder(target.valueAsNumber);
            }
          }}
          InputProps={{
            inputProps: { min: 1, step: 1 },
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  );
};

FilterSettings.propTypes = {
  enabled: PropTypes.object.isRequired,
  cutoff: PropTypes.object.isRequired,
  order: PropTypes.number.isRequired,
  onChangeEnabledFilters: PropTypes.func.isRequired,
  onChangeCutoffFreq: PropTypes.func.isRequired,
  onChangeFilterOrder: PropTypes.func.isRequired,
};

export default FilterSettings;
