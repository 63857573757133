import http from './httpService';

const baseUrl = '/api/jupyter';

/**
 * Fetch the user's notebook token. One will be created if it does not exist.
 *
 * @returns {Promise<string>} The token
 */
async function getToken() {
  const { data } = await http.post(`${baseUrl}/token`);
  return data.token;
}

export {
  getToken,
};
