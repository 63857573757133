/**
 * Lists devices in a table, allows creation of devices with a button that opens a modal.
 *
 * @module Devices
 */

import {
  Formik,
  Form,
  Field,
} from 'formik';
import { Select, TextField } from 'formik-material-ui';
import MaterialTable, { MTableAction } from '@material-table/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { getDevices, createDevice, updateDevicePartial } from '../services/devices';
import { AddDeviceSchema } from '../models/device';
import { colors } from '../colorscheme';

const AddDeviceButton = styled(Button)({
  margin: '10px',
  backgroundColor: colors.Button,
});

const Devices = (props) => {
  const { user } = props;

  const [data, setData] = useState([]);
  const [addDeviceModalOpen, setAddDeviceModalOpen] = useState(false);

  /**
   * Fetch devices from the API
   *
   * @async
   * @function fetchData
   * @returns {Promise} Resolves when data has been updated
   */
  const fetchData = async () => {
    const devices = await getDevices();
    setData(devices);
  };

  React.useEffect(() => {
    fetchData();
  }, [user]);

  const handleAddDeviceModalOpen = () => setAddDeviceModalOpen(true);
  const handleAddDeviceModalClose = () => setAddDeviceModalOpen(false);

  const handleDeviceSubmit = async (rowData, { setFieldError, setSubmitting }) => {
    try {
      // Create the new device
      await createDevice({ ...rowData, username: user.username });
      // Manually trigger a refresh of the devices from the API
      await fetchData();
      // Close the modal
      handleAddDeviceModalClose();
    } catch (err) {
      // Check if a conflict occurred due to a deviceId that already exists for another device
      if (err.response && err.response.status === 409) {
        setFieldError('deviceId', 'Device with this ID already exists');
        setSubmitting(false);
      } else {
        // TODO: Handle unexpected errors better (generic error popup)
        console.error(`Unexpected error: ${err.response}`);
      }
    }
  };

  const handleDisableDevice = async (event, rowData) => {
    const body = { disabled: !rowData.disabled };
    await updateDevicePartial(rowData.deviceId, body);
    await fetchData();
  };

  // The columns to display for the table
  const columns = [
    { title: 'Device ID', field: 'deviceId' },
    { title: 'Creator', field: 'username' },
  ];
  if (user.role === 'Admin') {
    columns.push({ title: 'Disabled', field: 'disabled' });
  }
  const actions = [
    {
      icon: '',
      tooltip: 'Add a device',
      // Use a single button at the top of the table instead of a button for each row
      isFreeAction: true,
      // Open the modal when clicked
      onClick: () => handleAddDeviceModalOpen(),
    },
  ];
  if (user.role === 'Admin') {
    actions.push({
      icon: 'block',
      tooltip: 'Disable/enable device',
      onClick: handleDisableDevice,
    });
  }
  return (
    <>
      <MaterialTable
        title="Devices"
        columns={columns}
        data={data}
        actions={actions}
        detailPanel={({ metadata }) => (
          <List>
            <ListItem dense>
              <ListItemText primary={`WISP Firmware Version: ${metadata?.wispFirmwareVersion}`} />
            </ListItem>
            <ListItem dense>
              <ListItemText primary={`Nano Image Version: ${metadata?.nanoImageVersion}`} />
            </ListItem>
            <ListItem dense>
              <ListItemText primary={`Dongle Firmware Version: ${metadata?.dongleFirmwareVersion}`} />
            </ListItem>
          </List>
        )}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        options={{
          paging: false,
          toolbarButtonAlignment: 'right',
          searchFieldAlignment: 'right',
          maxBodyHeight: '75vh',
          headerStyle: { position: 'sticky', top: 0 },
        }}
        // See: https://material-table.com/#/docs/features/component-overriding
        components={{
          Action: (componentProps) => {
            // Use our custom button for adding a device
            if (componentProps.action.tooltip === 'Add a device') {
              return (
                <AddDeviceButton
                  color="primary"
                  variant="contained"
                  onClick={(event) => componentProps.action.onClick(event, componentProps.data)}
                >
                  Add a device
                </AddDeviceButton>
              );
            }
            // Use the regular action component for inline actions
            return (
              <MTableAction
                {...componentProps} // eslint-disable-line react/jsx-props-no-spreading
              />
            );
          },
        }}
        // TODO: Figure out the behavior for deleting a device. What do we do with all the patients
        // who are associated with the deleted device?
        // editable={{
        //   onRowDelete: async (oldData) => {
        //     await deleteDevice(oldData.deviceId);
        //     await fetchData();
        //   },
        // }}
        localization={{
          body: {
            editRow: {
              deleteText: 'Are you sure you want to delete?',
            },
          },
        }}
      />
      <Dialog
        open={addDeviceModalOpen}
        onClose={handleAddDeviceModalClose}
        fullWidth
      >
        <DialogTitle>Add new device</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              deviceId: '',
              deviceName: '',
              type: 'WISP',
              metadata: {
                wispFirmwareVersion: '',
                nanoImageVersion: '',
                dongleFirmwareVersion: '',
              },
            }}
            validationSchema={AddDeviceSchema}
            onSubmit={handleDeviceSubmit}
          >
            {({ submitForm }) => (
              <Form>
                <Field
                  component={TextField}
                  name="deviceId"
                  label="Device ID"
                  required
                  fullWidth
                  margin="normal"
                />
                <Field
                  component={TextField}
                  name="deviceName"
                  label="Name"
                  fullWidth
                  margin="normal"
                />
                <FormControl
                  fullWidth
                  margin="normal"
                >
                  <InputLabel>Type</InputLabel>
                  <Field
                    component={Select}
                    name="type"
                    label="Type"
                    labelWidth="10"
                  >
                    <MenuItem key="WISP" value="WISP">WISP</MenuItem>
                  </Field>
                </FormControl>
                <Field
                  component={TextField}
                  name="metadata.wispFirmwareVersion"
                  label="WISP Firmware Version"
                  fullWidth
                  margin="normal"
                />
                <Field
                  component={TextField}
                  name="metadata.nanoImageVersion"
                  label="Nano Image Version"
                  fullWidth
                  margin="normal"
                />
                <Field
                  component={TextField}
                  name="metadata.dongleFirmwareVersion"
                  label="Dongle Firmware Version"
                  fullWidth
                  margin="normal"
                />
                <DialogActions>
                  <Button onClick={handleAddDeviceModalClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={submitForm} color="primary">
                    Add
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

Devices.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

export default Devices;
