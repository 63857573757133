import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import MaterialTable, { MTableAction, MTableEditField } from '@material-table/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
  getExperiments,
  createExperiment,
  updateExperimentById,
  deleteExperimentById,
} from '../services/experiments';
import ExperimentCollaborators from './ExperimentCollaborators';
import ExperimentFiles from './ExperimentFiles';
import ExperimentPatients from './ExperimentPatients';
import ConfirmDialog from './modal/ConfirmDialog';
import SvgIcon from '../icons';

// icon size in pts
const actionIconSize = 25;

const AddExperimentButton = styled(Button)({
  margin: '10px',
});

const AddExperimentSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .min(3)
    .max(100)
    .label('Name'),
  description: Yup.string()
    .required()
    .max(400)
    .label('Description'),
});

const experimentValidator = (field) => (rowData) => {
  try {
    Yup.reach(AddExperimentSchema, field).validateSync(rowData[field]);
    return { isValid: true, helperText: '' };
  } catch (e) {
    return { isValid: false, helperText: e.message };
  }
};

/**
 * Experiments component
 *
 * @param {object} props - component properties
 * @param {object} props.user - user object
 */
const Experiments = (props) => {
  const { user } = props;
  const history = useHistory();
  const { experimentId: selectedExperimentId, action: selectedAction } = useParams();

  const [data, setData] = useState([]);
  const [addExperimentModalOpen, setAddExperimentModalOpen] = useState(false);

  // configuration for the confirm dialog spawned when deleting experiments
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [rowToDelete, setRowToDelete] = useState({});

  const fetchData = async () => {
    const experiments = await getExperiments();
    setData(experiments);
  };

  React.useEffect(() => {
    fetchData();
  }, [user.username]);

  const selectedExperiment = data.find((item) => item.uuid === selectedExperimentId);

  const handleAddExperimentModalOpen = () => setAddExperimentModalOpen(true);
  const handleAddExperimentModalClose = () => setAddExperimentModalOpen(false);

  const handleExperimentSubmit = async (
    { name, description },
  ) => {
    try {
      await createExperiment(name, description);
      await fetchData();
      handleAddExperimentModalClose();
    } catch (err) {
      // TODO: Handle unexpected errors better (generic error popup)
      console.error(`Unexpected error: ${err.response}`);
    }
  };

  const handleDeleteExperiment = async () => {
    try {
      await deleteExperimentById(rowToDelete.uuid);
    } catch (err) {
      toast.error(err);
    }
    await fetchData();
    setConfirmDeleteOpen(false);
  };

  const handleSelectAction = (action, rowData) => {
    history.push(`/experiments/${rowData.uuid}/${action}`);
  };

  // The columns to display for the table
  const columns = [
    { title: 'Name', field: 'name', validate: experimentValidator('name') },
    {
      title: 'Description',
      field: 'description',
      validate: experimentValidator('description'),
      editComponent: ((componentProps) => (
        <MTableEditField
          {...componentProps} // eslint-disable-line react/jsx-props-no-spreading
          multiline
        />
      )),
    },
    { title: 'Owner', field: 'owner', editable: false },
  ];
  const actions = [
    {
      icon: SvgIcon({
        name: 'ShowFiles',
        width: actionIconSize,
        height: actionIconSize,
      }),
      tooltip: 'Show Files',
      onClick: (event, rowData) => {
        handleSelectAction('list_files', rowData);
      },
    },
    {
      icon: SvgIcon({
        name: 'ShowParticipants',
        width: actionIconSize,
        height: actionIconSize,
      }),
      tooltip: 'Show Participants',
      onClick: (event, rowData) => {
        handleSelectAction('list_participants', rowData);
      },
    },
    {
      icon: SvgIcon({
        name: 'ShowCollaborators',
        width: actionIconSize,
        height: actionIconSize,
      }),
      tooltip: 'Show Collaborators',
      onClick: (event, rowData) => {
        handleSelectAction('list_collaborators', rowData);
      },
    },
  ];
  if (user.role !== 'Researcher') {
    actions.push({
      icon: '',
      tooltip: 'Add an experiment',
      // Use a single button at the top of the table instead of a button for each row
      isFreeAction: true,
      // Open the modal when clicked
      onClick: () => handleAddExperimentModalOpen(),
    });
  }
  if (user.role === 'Admin') {
    actions.push({
      icon: SvgIcon({
        name: 'Delete',
        width: actionIconSize,
        height: actionIconSize,
      }),
      tooltip: 'Delete an experiment',
      // Open the modal when clicked
      onClick: async (event, row) => {
        setDeleteMessage(`Are you sure you want to delete '${row.name}'?`);
        setRowToDelete(row);
        setConfirmDeleteOpen(true);
      },
    });
  }
  return (
    <>
      <MaterialTable
        title="Experiments"
        columns={columns}
        data={data}
        actions={actions}
        onRowClick={(event, rowData) => {
          handleSelectAction('none', rowData);
        }}
        icons={{
          Edit: SvgIcon({
            name: 'EditFile',
            width: actionIconSize,
            height: actionIconSize,
          }),
        }}
        editable={{
          isEditable: ({ owner }) => user.role === 'Admin' || owner === user.username,
          onRowUpdate: async (newData, oldData) => {
            try {
              if (newData.name !== oldData.name || newData.description !== oldData.description) {
                await updateExperimentById(oldData.uuid, newData);
              }
            } catch (err) {
              toast.error(err);
            }
            await fetchData();
          },
        }}
        options={{
          paging: false,
          toolbarButtonAlignment: 'right',
          searchFieldAlignment: 'right',
          rowStyle: (rowData) => ({
            backgroundColor: selectedExperimentId === rowData.uuid ? '#EEE' : '#FFF',
          }),
          maxBodyHeight: '45vh',
          headerStyle: { position: 'sticky', top: 0 },
        }}
        // See: https://material-table.com/#/docs/features/component-overriding
        components={{
          Action: (componentProps) => {
            if (componentProps.action.tooltip === 'Add an experiment') {
              return (
                <AddExperimentButton
                  color="primary"
                  variant="contained"
                  onClick={(event) => componentProps.action.onClick(event, componentProps.data)}
                >
                  Add an experiment
                </AddExperimentButton>
              );
            }
            // Use the regular action component for inline actions
            return (
              <MTableAction
                {...componentProps} // eslint-disable-line react/jsx-props-no-spreading
              />
            );
          },
        }}
      />
      {selectedExperiment && selectedAction === 'list_collaborators' && (
        <ExperimentCollaborators
          username={user.username}
          experimentId={selectedExperiment.uuid}
          allowModify={selectedExperiment.owner === user.username || user.role === 'Admin'}
        />
      )}
      {selectedExperiment && selectedAction === 'list_files' && (
        <ExperimentFiles
          username={user.username}
          experimentId={selectedExperiment.uuid}
          patients={selectedExperiment.patients}
          files={selectedExperiment.files}
          allowDelete={selectedExperiment.owner === user.username || user.role === 'Admin'}
          refresh={fetchData}
          onWorkflowUpdate={props.onWorkflowUpdate}
        />
      )}
      {selectedExperiment && selectedAction === 'list_participants' && (
        <ExperimentPatients
          experimentId={selectedExperiment.uuid}
          allowModify={(selectedExperiment.owner === user.username || user.role === 'Admin')}
          refresh={fetchData}
        />
      )}
      <Dialog
        open={addExperimentModalOpen}
        onClose={handleAddExperimentModalClose}
        fullWidth
      >
        <DialogTitle>Add new experiment</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ name: '', description: '' }}
            validationSchema={AddExperimentSchema}
            onSubmit={handleExperimentSubmit}
          >
            {({ submitForm }) => (
              <Form>
                <Field
                  component={TextField}
                  name="name"
                  label="Name"
                  required
                  fullWidth
                  margin="normal"
                />
                <Field
                  component={TextField}
                  name="description"
                  label="Description"
                  required
                  fullWidth
                  margin="normal"
                />
                <DialogActions>
                  <Button onClick={handleAddExperimentModalClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={submitForm} color="primary">
                    Add
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        title="Delete Experiment"
        message={deleteMessage}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={() => handleDeleteExperiment()}
      />
    </>
  );
};

Experiments.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

export default Experiments;
