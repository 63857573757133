import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';

import { runWorkflow } from '../services/workflows';
import { getEventCodes } from '../services/files';
import ErpSettingsDialog from './modal/ErpSettingsDialog';

const type = 'erp';

/**
 * ErpMenuItem
 *
 * renders a menu item for the ERP workflow
 *
 * @param {Object} props - component's properties
 * @param {string} props.title - message to be displayed as a tooltip
 * @param {boolean} props.disabled - if true, the menu item will be disabled
 * @param {function} props.onClick - invoked when menu item is clicked
 * @param {function} props.onWorkflowStart - invoked when workflow starts
 * @param {function} props.onWorkflowFinish - invoked when workflow finishes
 * @param {function} props.onWorkflowError - invoked when workflow fails
 * @param {Object.<string, string>} props.fileProps - file properties
 * @returns {React.Component} - returns a menu item which opens a modal dialog when clicked
 */
const ErpMenuItem = ({
  title,
  disabled,
  onClick,
  onWorkflowStart,
  onWorkflowFinish,
  onWorkflowError,
  fileProps,
}) => {
  const [open, setOpen] = useState(false);
  const [availableCodes, setAvailableCodes] = useState([]);
  const noCodes = availableCodes.length === 0;
  const message = noCodes ? 'Unable to obtain event codes' : '';
  const {
    experimentId,
    patientId,
    fileId,
  } = fileProps;

  useEffect(() => {
    const newFileProps = { experimentId, patientId, fileId };
    getEventCodes(newFileProps).then((sortedCodes) => {
      setAvailableCodes(sortedCodes);
    });
  }, [experimentId, patientId, fileId]);

  const handleRunWorkflow = async (settings, outputFilename) => {
    setOpen(false);
    if (settings && outputFilename) {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      const workflowArgs = {
        experimentId,
        patientId,
        name: outputFilename,
        fileIds: [fileId],
        type,
        settingsId: settings.uuid,
        timeZone,
        outputFilename,
      };
      try {
        // TODO: handle this better in the backend.
        runWorkflow(workflowArgs)
          .then(onWorkflowFinish)
          .catch(({ response }) => {
            const errorMessage = response?.data?.message
              || `Could not start workflow from file ${fileId}`;
            toast.error(errorMessage);
          });
        // Add a pause here before executing `onWorkflowStart`
        // in order to wait for the new workflow to start.
        await new Promise((resolve) => setTimeout(resolve, 200));
        onWorkflowStart();
      } catch (error) {
        toast.error(`Unexpected error while running ERP workflow from file ${fileId}`);
        onWorkflowError();
      }
    }
  };

  return fileProps.fileType === 'mff' && (
    <Tooltip title={title || message}>
      <div>
        <MenuItem
          disabled={disabled || noCodes}
          onClick={() => {
            onClick();
            setOpen(true);
          }}
        >
          ERP
        </MenuItem>
        <ErpSettingsDialog
          open={open}
          experimentId={experimentId}
          patientId={patientId}
          filename={fileProps.filename}
          availableCodes={availableCodes}
          onClose={handleRunWorkflow}
        />
      </div>
    </Tooltip>
  );
};

ErpMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onWorkflowStart: PropTypes.func.isRequired,
  onWorkflowFinish: PropTypes.func.isRequired,
  onWorkflowError: PropTypes.func.isRequired,
  fileProps: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ErpMenuItem;
