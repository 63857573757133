import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Tooltip } from '@material-ui/core';

/**
 * SourcererMenuItem
 *
 * renders a menu item for Sourcerer workflow
 *
 * @param {Object} props - component's properties
 * @param {string} props.title - message to be displayed as a tooltip
 * @param {boolean} props.disabled - if true, the menu item will be disabled
 * @param {bool} props.onClick - invoked when menu item is clicked
 * @param {string} props.fileType - type of file
 * @param {Object.<string, string>} props.fileProps - file properties
 * @returns {React.Component} - returns a menu item
 */
const SourcererMenuItem = ({
  title,
  disabled,
  onClick,
  fileType,
}) => fileType === 'mff' && (
<Tooltip title={title}>
  <div>
    <MenuItem
      disabled={disabled}
      onClick={onClick}
    >
      SOURCERER
    </MenuItem>
  </div>
</Tooltip>
);

SourcererMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired,
};

export default SourcererMenuItem;
