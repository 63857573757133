import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';

import { runWorkflow } from '../services/workflows';
import NeatSettingsDialog from './modal/NeatSettingsDialog';

const type = 'neat';

/**
 * NeatMenuItem
 *
 * renders a menu item for the NEAT workflow
 *
 * @param {Object} props - component's properties
 * @param {string} props.title - message to be displayed as a tooltip
 * @param {boolean} props.disabled - if true, the menu item will be disabled
 * @param {function} props.onClick - invoked when menu item is clicked
 * @param {function} props.onWorkflowStart - invoked when workflow starts
 * @param {function} props.onWorkflowFinish - invoked when workflow finishes
 * @param {function} props.onWorkflowError - invoked if workflow fails
 * @param {Object.<string, string>} props.fileProps - file properties
 * @returns {React.Component} - returns a menu item which opens a modal dialog when clicked
 */
const NeatMenuItem = ({
  title,
  disabled,
  onClick,
  onWorkflowStart,
  onWorkflowFinish,
  onWorkflowError,
  fileProps,
}) => {
  const [open, setOpen] = useState(false);
  const {
    experimentId,
    patientId,
    fileId,
  } = fileProps;

  const handleRunWorkflow = async (model) => {
    setOpen(false);
    if (model) {
      const workflowArgs = {
        experimentId,
        patientId,
        fileIds: [fileId],
        type,
        model,
      };
      try {
        // TODO: handle this better in the backend.
        runWorkflow(workflowArgs).then((workflowId) => {
          onWorkflowFinish(workflowId);
        });
        // Add a pause here before executing `onWorkflowStart`
        // in order to wait for the new workflow to start.
        await new Promise((resolve) => setTimeout(resolve, 200));
        onWorkflowStart();
      } catch (error) {
        toast.error(`Unexpected error while running NEAT workflow from file ${fileId}`);
        onWorkflowError();
      }
    }
  };

  return fileProps.fileType === 'mff' && (
    <Tooltip title={title}>
      <div>
        <MenuItem
          disabled={disabled}
          onClick={() => {
            onClick();
            setOpen(true);
          }}
        >
          NEAT
        </MenuItem>
        <NeatSettingsDialog
          open={open}
          onClose={handleRunWorkflow}
        />
      </div>
    </Tooltip>
  );
};

NeatMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onWorkflowStart: PropTypes.func.isRequired,
  onWorkflowFinish: PropTypes.func.isRequired,
  onWorkflowError: PropTypes.func.isRequired,
  fileProps: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default NeatMenuItem;
