import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { getCurrentUser } from '../services/authService';

import Experiments from './Experiments';

/**
 * ExperimentsRoute
 *
 * renders an Experiment tab for the Experiments view
 *
 * @param {object} props - component properties
 * @param {function} props.onWorkflowUpdate - invoked when a
 * workflow starts, finishes and fails.
 * @returns {React.Component} - returns a Route component if user
 * is authenticated. Otherwise, redirects to the login page.
 */
const ExperimentsRoute = ({
  onWorkflowUpdate,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const user = getCurrentUser();
      if (!user) {
        return <Redirect to="/login" />;
      }
      return <Experiments onWorkflowUpdate={onWorkflowUpdate} {...props} user={user} />;
    }}
  />
);

ExperimentsRoute.propTypes = {
  onWorkflowUpdate: PropTypes.func.isRequired,
};

export default ExperimentsRoute;
