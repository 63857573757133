import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  InputAdornment,
  Grid,
} from '@material-ui/core';

/**
 * TimePadding
 *
 * renders a couple of numeric boxes to set left and right padding
 *
 * @param {Object} props - component's properties
 * @param {function} props.onChange - invoked when left or right padding changes
 * @param {Object.<string, number>} props.padding - contains left and right padding
 * @returns {React.Component} - returns a couple of numeric boxes
 */
const TimePadding = ({ onChange, padding }) => {
  const handleChangePadding = (key, value) => {
    onChange({ ...padding, [key]: value });
  };

  const handleLeavePadding = (key, value) => {
    const isValidNumber = !Number.isNaN(value);
    const rounded = isValidNumber ? Math.round(value) : 0;
    const positiveValue = rounded < 0 ? 0 : rounded;
    const validValue = positiveValue > 15000 ? 15000 : positiveValue;
    onChange({ ...padding, [key]: validValue });
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <TextField
          id="left-padding"
          label="Time before event"
          type="number"
          defaultValue="0"
          value={padding.left}
          onChange={({ target }) => handleChangePadding('left', target.valueAsNumber)}
          onBlur={({ target }) => handleLeavePadding('left', target.valueAsNumber)}
          onKeyPress={({ target, key }) => {
            if (key === 'Enter') {
              handleLeavePadding('left', target.valueAsNumber);
            }
          }}
          InputProps={{
            inputProps: { min: 0, step: 1, max: 15000 },
            endAdornment: <InputAdornment position="end">ms</InputAdornment>,
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="right-padding"
          label="Time after event"
          type="number"
          defaultValue="0"
          value={padding.right}
          onChange={({ target }) => handleChangePadding('right', target.valueAsNumber)}
          onBlur={({ target }) => handleLeavePadding('right', target.valueAsNumber)}
          onKeyPress={({ target, key }) => {
            if (key === 'Enter') {
              handleLeavePadding('right', target.valueAsNumber);
            }
          }}
          InputProps={{
            inputProps: { min: 0, step: 1, max: 15000 },
            endAdornment: <InputAdornment position="end">ms</InputAdornment>,
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  );
};

TimePadding.propTypes = {
  onChange: PropTypes.func.isRequired,
  padding: PropTypes.object.isRequired,
};

export default TimePadding;
