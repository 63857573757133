/**
 * Display files for an individual patient
 * @module PatientFiles
 */

import fileDownload from 'js-file-download';
import MaterialTable from '@material-table/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Add as AddIcon,
  ArrowUpward as ArrowUpwardIcon,
  CancelOutlined as CancelIcon,
  CreateNewFolder as CreateNewFolderIcon,
  Description as DescriptionIcon,
  Folder as FolderIcon,
  SaveAlt as SaveAltIcon,
  Subject as SubjectIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@material-ui/icons';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import http from '../services/httpService';
import { newDirectory } from '../services/patients';
import WispLog from './WispLog';
import WispSleepStats from './WispSleepStats';
import WispImpedances from './WispImpedances';
import WorkflowAction from './WorkflowAction';
import License from '../services/license';
import { getWorkflowInfo, getHistoryInfo, filenameFromUrl } from '../services/files';
import FileInfoDialog from './modal/FileInfoDialog';
import ConfirmDialog from './modal/ConfirmDialog';

const baseUrl = '/api/patients';

const useStyles = makeStyles({
  paper: {
    height: '80%',
  },
});

function PatientFiles({ user, patient, directoryId }) {
  const history = useHistory();
  const classes = useStyles();

  const [filesWrapper, setFilesWrapper] = useState({
    parent: '',
    files: [],
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [wispLogOpen, setWispLogOpen] = useState(false);
  const closeWispLog = () => setWispLogOpen(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [fileID, setFileID] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [wispTab, setWispTab] = useState('logs');
  const [hasLogs, setHasLogs] = useState(false);
  const [hasSleepStats, setHasSleepStats] = useState(false);
  const [hasImp, setHasImp] = useState(false);
  const [fileInfo, setFileInfo] = useState({});
  const [fileInfoOpen, setFileInfoOpen] = useState(false);
  // configuration for the confirm dialog spawned when downloading files
  const [confirmDownloadOpen, setConfirmDownloadOpen] = useState(false);
  const [fileToDownload, setFileToDownload] = useState('');
  const [downloadMessage, setDownloadMessage] = useState('');
  // configuration for the confirm dialog spawned when deleting patient files
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [rowToDelete, setRowToDelete] = useState({});
  const [features, setFeatures] = useState({});

  /**
   * Fetch licensed features
   *
   * @function getFeatures
   */
  const getFeatures = async () => {
    const license = new License();
    await license.update();
    setFeatures(license.features);
  };

  useEffect(() => { getFeatures(); }, []);

  /**
   * Fetch the list of files for a patient and update table
   *
   * @function fetchData
   */
  const fetchData = useCallback(async () => {
    console.log(`patient.medicalID: ${patient.medicalID}`);
    const { data } = await http.get(
      // TODO: Fix the bug when patient.medicalID is undefined
      `${baseUrl}/files/${patient.medicalID}/${directoryId}/`,
    );
    setFilesWrapper(data);
  }, [patient, directoryId]);

  useEffect(() => {
    if (patient) {
      fetchData();
    }
  }, [patient, fetchData]);

  if (!patient) {
    return null;
  }

  function handleNewButtonClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleNewButtonClose() {
    setAnchorEl(null);
  }

  function handleNewFolder() {
    setAnchorEl(null);
    setDialogOpen(true);
  }

  function handleCloseDialog() {
    setDialogOpen(false);
  }

  async function handleCreateFolder() {
    setDialogOpen(false);
    // TODO: validate response from API
    await newDirectory(
      patient.medicalID,
      user.username,
      directoryId,
      newFolderName,
    );
    await fetchData();
    console.log(newFolderName);
  }

  function handleRowClick(event, rowData) {
    if (rowData.directory) {
      history.push(`/participants/${patient.medicalID}/${rowData.uuid}`);
    }
  }

  function handleUpALevel() {
    history.push(`/participants/${patient.medicalID}/${filesWrapper.parent}`);
  }

  async function handleDeleteFile() {
    try {
      await http.delete(
        `${baseUrl}/${patient.medicalID}/files/${rowToDelete.uuid}/`,
      );
      fetchData();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        // TODO: display this better
        alert('Cannot delete a nonempty directory');
      }
    }
    setConfirmDeleteOpen(false);
  }

  async function handleFileDownload(fileId) {
    // Fetch the file from the API
    const { data, headers } = await http.get(
      `${baseUrl}/${patient.medicalID}/files/${fileId}/`,
      { responseType: 'blob' },
    );
    // Parse the content disposition header to get the filename
    const contentDisposition = headers['content-disposition'];
    const filename = contentDisposition.match(/filename="(.*)"/)[1];
    fileDownload(data, filename);
  }

  async function handleDownload() {
    setIsLoading(true);
    await handleFileDownload(fileToDownload);
    setIsLoading(false);
    setConfirmDownloadOpen(false);
  }

  async function handleViewFileInfo(index) {
    const file = filesWrapper.files[index];
    const workflowInfo = await getWorkflowInfo(patient.medicalID, file.uuid);
    const historyInfo = await getHistoryInfo(patient.medicalID, file.uuid);
    setFileInfo({ file, workflowInfo, historyInfo });
    setFileInfoOpen(true);
  }

  const columns = [
    {
      title: 'Filename',
      cellStyle: {
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
      },
      render: (file) => {
        const url = file.url.match(/^.*::(.*)$/)[1];
        return (
          <>
            {file.directory ? <FolderIcon color="action" /> : <DescriptionIcon color="action" />}
            {` ${url}`}
          </>
        );
      },
    },
    {
      title: 'Date',
      field: 'date',
      cellStyle: { whiteSpace: 'nowrap' },
      render: ({ date }) => new Date(date).toLocaleString(),
      defaultSort: 'desc',
    },
    { title: 'Type', field: 'type' },
    {
      title: 'Status',
      field: 'status',
      render: ({ details, status }) => (
        <Tooltip title={details}>
          <span>{status}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Action',
      render: (file) => (
        <WorkflowAction
          disabled={['error', 'processing'].includes(file.status)}
          tooltip={file.details}
          filename={file.url.split('::')[1]}
          fileType={file.type}
          fileStatus={file.status}
          patientId={patient.medicalID}
          age={patient.age}
          fileId={file.uuid}
          username={user.username}
          refresh={fetchData}
        />
      ),
    },
  ];

  if (features.devices) {
    columns.splice(3, 0, { title: 'Device ID', field: 'deviceID' });
  }

  const actions = [
    (rowData) => ({
      icon: SaveAltIcon,
      tooltip: 'Download file',
      disabled: rowData.directory,
      onClick: async () => {
        const file = filenameFromUrl(rowData.url);
        setDownloadMessage(`This action will be recorded. Are you sure you want to download '${file}'?`);
        setFileToDownload(rowData.uuid);
        setConfirmDownloadOpen(true);
      },
    }),
    (rowData) => ({
      icon: InfoOutlinedIcon,
      tooltip: 'View file info',
      onClick: (event, { tableData }) => {
        handleViewFileInfo(tableData.id);
      },
      disabled: rowData.type !== 'mff',
    }),
    (rowData) => ({
      icon: SubjectIcon,
      tooltip: 'View WISP info',
      disabled: !rowData?.extraFiles.includes('recording.log'),
      onClick: (event, { uuid, extraFiles }) => {
        setFileID(uuid);
        setHasLogs(extraFiles.includes('recording.log'));
        setHasSleepStats(extraFiles.includes('sleep_statistics.json'));
        setHasImp(extraFiles.includes('impedances.json'));
        setWispLogOpen(true);
      },
    }),
    {
      icon: 'refresh',
      tooltip: 'Refresh data',
      isFreeAction: true,
      onClick: async () => {
        setIsLoading(true);
        try {
          await fetchData();
        } finally {
          setIsLoading(false);
        }
      },
    },
    {
      icon: 'delete_outline',
      tooltip: 'Delete a file',
      // Open the modal when clicked
      onClick: async (event, row) => {
        const filename = filenameFromUrl(row.url);
        setDeleteMessage(`This action will be recorded. Are you sure you want to delete '${filename}'?`);
        setRowToDelete(row);
        setConfirmDeleteOpen(true);
      },
    },
  ];

  return (
    <>
      <Button
        style={{
          marginTop: '20px',
          marginBottom: '20px',
        }}
        variant="contained"
        color="default"
        size="small"
        startIcon={<ArrowUpwardIcon />}
        onClick={handleUpALevel}
        disabled={filesWrapper.parent === '-1'}
      >
        UP A LEVEL
      </Button>
      <MaterialTable
        title="Files"
        isLoading={isLoading}
        columns={columns}
        actions={actions}
        data={filesWrapper.files}
        onRowClick={handleRowClick}
        options={{
          paging: false,
          padding: 'dense',
          maxBodyHeight: '45vh',
        }}
      />
      <div>
        <br />
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="default"
          size="large"
          startIcon={<AddIcon />}
          onClick={(e) => handleNewButtonClick(e)}
        >
          New
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleNewButtonClose}
        >
          <MenuItem onClick={handleNewFolder}>
            <ListItemIcon>
              <CreateNewFolderIcon />
            </ListItemIcon>
            <ListItemText primary="Folder" />
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleNewButtonClose}>
            <ListItemIcon>
              <CancelIcon />
            </ListItemIcon>
            <ListItemText primary="Cancel" />
          </MenuItem>
        </Menu>
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">New Folder</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the name of the folder you wish to create...
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Folder Name"
              fullWidth
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCreateFolder} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={wispLogOpen}
          onClose={closeWispLog}
          maxWidth="xl"
          fullWidth
          classes={classes}
        >
          <DialogContent>
            <TabContext value={wispTab}>
              <TabList onChange={(event, newValue) => { setWispTab(newValue); }}>
                <Tab label="Logs" value="logs" disabled={!hasLogs} />
                <Tab label="Sleep Statistics" value="sleepStats" disabled={!hasSleepStats} />
                <Tab label="Impedances" value="impedances" disabled={!hasImp} />
              </TabList>
              <TabPanel value="logs">
                <WispLog patientId={patient.medicalID} fileId={fileID} />
              </TabPanel>
              <TabPanel value="sleepStats">
                <WispSleepStats patientId={patient.medicalID} fileId={fileID} />
              </TabPanel>
              <TabPanel value="impedances">
                <WispImpedances patientId={patient.medicalID} fileId={fileID} />
              </TabPanel>
            </TabContext>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeWispLog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <FileInfoDialog
          open={fileInfoOpen}
          info={fileInfo}
          onClose={() => setFileInfoOpen(false)}
        />
        <ConfirmDialog
          title="Download file"
          message={downloadMessage}
          open={confirmDownloadOpen}
          setOpen={setConfirmDownloadOpen}
          onConfirm={() => handleDownload()}
        />
        <ConfirmDialog
          title="Delete Participant File"
          message={deleteMessage}
          open={confirmDeleteOpen}
          setOpen={setConfirmDeleteOpen}
          onConfirm={() => handleDeleteFile()}
        />
      </div>
    </>
  );
}

PatientFiles.propTypes = {
  user: PropTypes.shape({ username: PropTypes.string }).isRequired,
  patient: PropTypes.shape({
    medicalID: PropTypes.string,
    age: PropTypes.string,
  }).isRequired,
  directoryId: PropTypes.string.isRequired,
};

export default PatientFiles;
