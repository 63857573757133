/* eslint-disable max-classes-per-file */
import http from './httpService';

const enableInternal = process.env.REACT_APP_ENABLE_INTERNAL === 'true';

export class NoFreeLicensesError extends Error {
  constructor(feature) {
    super();
    this.message = 'No free licenses';
    if (feature) {
      this.message += ` for ${feature}`;
    }
  }
}

export default class License {
  constructor() {
    this.features = License.defaults();
    this.token = null;
  }

  static async getLicenses() {
    try {
      const { data } = await http.get('/api/license');
      return data;
    } catch ({ response }) {
      const { message } = response.data;
      throw new Error(message);
    }
  }

  async reserve(feature) {
    try {
      const { data } = await http.get(`/api/license/valid/${feature}`);
      this.token = data.uuid;
    } catch ({ response }) {
      if (response.status === 422) {
        throw new NoFreeLicensesError(feature);
      } else {
        throw new Error(response.data.message);
      }
    }
  }

  async update() {
    try {
      const { data } = await http.get('/api/license/available');
      this.features = { ...this.features, ...data };
    } catch (err) {
      console.error(err);
    }
  }

  static async revoke(licenseId) {
    try {
      await http.post(`/api/license/revoke/${licenseId}`);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      } else {
        throw err;
      }
    }
  }

  /**
   * checks whether a license is valid or not
   *
   * @param {string} licenseId - a license id
   * @returns {boolean} true if license is valid
   */
  static async valid(licenseId) {
    try {
      const url = `/api/license/status/${licenseId}`;
      const { data: licenseStatus } = await http.get(url);
      return licenseStatus.isValid;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  static defaults() {
    return {
      erp: true,
      neat: enableInternal,
      devices: enableInternal,
      belview: true,
      notebooks: true,
      sourcerer: false,
      participants: enableInternal,
    };
  }
}
