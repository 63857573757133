import filesize from 'filesize';
import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getDiskSpace } from '../services/stats';

const useStyles = makeStyles((theme) => ({
  linearProgress: {
    borderRadius: 5,
    height: '10px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  typography: {
    fontSize: 14,
  },
}));

const DiskSpace = () => {
  const classes = useStyles();
  const [diskSpace, setDiskSpace] = useState(null);

  const fetchData = async () => {
    const newDiskSpace = await getDiskSpace();
    setDiskSpace(newDiskSpace);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return diskSpace && (
    <Box display="flex" alignItems="center" flexDirection="column" my={1}>
      <Typography>
        Storage space
      </Typography>
      <Box width="100%">
        <LinearProgress
          variant="determinate"
          className={classes.linearProgress}
          value={(diskSpace.bytesUsed / diskSpace.bytesTotal) * 100}
        />
      </Box>
      <Typography className={classes.typography}>
        {`${filesize(diskSpace.bytesUsed)} / ${filesize(diskSpace.bytesTotal)}`}
      </Typography>
    </Box>
  );
};

export default DiskSpace;
