import fileDownload from 'js-file-download';

import http from './httpService';

const baseUrl = '/api/experiments';

/**
 * returns files associated with a participant
 *
 * @param {string} experimentId - an experiment id
 * @param {string} patientId - a patient id
 * @returns {Object[]} - a list of files
 */
const getFiles = async (experimentId, patientId) => {
  const url = `${baseUrl}/${experimentId}/${patientId}/files`;
  const { data: files } = await http.get(url);
  return files;
};

/**
 * Get experiments
 *
 * @return {Promise<Object[]>} List of experiments
 */
const getExperiments = async () => {
  const { data: devices } = await http.get(baseUrl);
  return devices;
};

/**
 * Create a new experiment
 *
 * @param {string} name
 * @param {string} description
 */
const createExperiment = async (name, description) => http.post(baseUrl, { name, description });

/**
 * Update an existing experiment
 *
 * @param {string} experimentId - Experiment ID
 * @param {Object} body - New experiment body
 */
const updateExperimentById = async (experimentId, body) => http.put(
  `${baseUrl}/${experimentId}`,
  body,
);

/**
 * Delete an experiment
 *
 * @param {string} experimentId - Experiment ID
 */
const deleteExperimentById = async (experimentId) => http.delete(`${baseUrl}/${experimentId}`);

/**
 * Get experiment patients
 *
 * @param {string} experimentId - Experiment ID
 * @return {Promise<Object[]>} Experiment patients
 */
const getExperimentPatients = async (experimentId) => {
  const { data: patients } = await http.get(`${baseUrl}/${experimentId}/patients`);
  return patients;
};

/**
 * Get experiment collaborators
 *
 * @param {string} experimentId - Experiment ID
 * @return {Promise<Object[]>} Experiment collaborators
 */
const getExperimentCollaborators = async (experimentId) => {
  const { data: collaborators } = await http.get(
    `${baseUrl}/${experimentId}/collaborators`,
  );
  return collaborators;
};

/**
 * Add experiment collaborators
 *
 * @param {string} experimentId - Experiment ID
 * @param {Object[]} collaborators - New collaborators
 */
const addExperimentCollaborators = async (experimentId, collaborators) => http.post(
  `${baseUrl}/${experimentId}/collaborators`,
  collaborators,
);

/**
 * Delete experiment collaborator
 *
 * @param {string} experimentId
 * @param {string} collaboratorUsername
 */
const deleteExperimentCollaborator = async (experimentId, collaboratorUsername) => http.delete(
  `${baseUrl}/${experimentId}/collaborators/${collaboratorUsername}`,
);

/**
 * Add experiment patient
 *
 * @param {string} experimentId - Experiment ID
 * @param {Object} patient - New patient
 */
const addExperimentPatient = async (experimentId, patient) => http.post(
  `${baseUrl}/${experimentId}/patients`,
  patient,
);

/**
 * Delete an experiment patient
 *
 * @param {string} experimentId
 * @param {string} patientId
 */
const deleteExperimentPatient = async (experimentId, patientId) => http.delete(
  `${baseUrl}/${experimentId}/patients/${patientId}`,
);

/**
 * Trigger a browser download of an experiment file
 *
 * @param {string} experimentId - Experiment ID
 * @param {string} fileId - File ID
 */
const downloadExperimentFile = async (experimentId, fileId) => {
  const { data, headers } = await http.get(
    `${baseUrl}/${experimentId}/files/${fileId}`,
    { responseType: 'blob' },
  );
  // Parse the content disposition header to get the filename
  const contentDisposition = headers['content-disposition'];
  const filename = contentDisposition.match(/filename="(.*)"/)[1];
  fileDownload(data, filename);
};

const deleteExperimentFile = async (experimentId, fileId) => http.delete(
  `${baseUrl}/${experimentId}/files/${fileId}`,
);

/**
 * Get all workflow settings of an experiment
 *
 * @param {string} experimentId
 * @returns {object} contains workflow settings
 */
const getAvailableSettings = async (experimentId) => {
  const url = `${baseUrl}/${experimentId}/workflowSettings`;
  const { data } = await http.get(url);
  return data;
};

/**
 * Submit workflow settings of a given type to an experiment.
 *
 * If an id is provided in the `settings` parameter, workflow
 * settings will be ovewritten.
 *
 * @param {string} experimentId - experiment ID
 * @param {string} type - workflow type
 * @param {object} settings - workflow settings to be submitted
 */
const submitWorkflowSettings = async (experimentId, type, settings) => {
  const url = `${baseUrl}/${experimentId}/workflowSettings/${type}`;
  await http.post(url, { settings });
};

/**
 * Delete workflow settings of a given type in an experiment.
 *
 * @param {string} experimentId - experiment ID
 * @param {string} type - workflow type
 * @param {string} settingsId - id of workflow settings to be deleted
 */
const deleteWorkflowSettings = async (experimentId, type, settingsId) => {
  const url = `${baseUrl}/${experimentId}/workflowSettings/${type}${settingsId}`;
  await http.delete(url);
};

export {
  getFiles,
  getExperiments,
  createExperiment,
  updateExperimentById,
  deleteExperimentById,
  getExperimentPatients,
  getExperimentCollaborators,
  addExperimentCollaborators,
  deleteExperimentCollaborator,
  addExperimentPatient,
  deleteExperimentPatient,
  downloadExperimentFile,
  deleteExperimentFile,
  getAvailableSettings,
  submitWorkflowSettings,
  deleteWorkflowSettings,
};
