import React from 'react';
import PropTypes from 'prop-types';

import ConfirmDialog from '../modal/ConfirmDialog';
import { deleteUser } from '../../services/users';

/**
 * Dialog for deleting a user
 *
 * @param {Object} props
 * @param {string} props.username - The username of the user attempting to be deleted
 * @param {boolean} props.open - Whether the dialog should be open or not
 * @param {function} props.setOpen - Function called to set open to true or false
 * @param {function} props.onClose - Function to be called when the dialog is closed
 * @returns {React.Component}
 */
const DeleteUserDialog = ({
  username,
  open,
  setOpen,
  onClose,
}) => {
  const handleDelete = async () => {
    await deleteUser(username);
    onClose();
  };

  return (
    <ConfirmDialog
      title="Delete User Account"
      message={`Are you sure you want to delete '${username}'?`}
      open={open}
      setOpen={setOpen}
      onConfirm={handleDelete}
    />
  );
};

DeleteUserDialog.propTypes = {
  username: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteUserDialog;
