import { Formik, Form, Field } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import {
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { RegisterUser } from '../../models/user';
import { registerUser } from '../../services/users';

/**
 * Element Styles
 * --------------
 *
 * - password: all latters are obfuscated with discs
 */
const useStyles = makeStyles({
  password: {
    '-webkit-text-security': 'disc',
  },
});

// 'Clinician' seems to have been replaced by Lab Manager (at least for now)
const ROLE_NAMES = [
  'Lab Manager',
  'Researcher',
  'Admin',
];

const TextFieldNoAutofill = ({ ...props }) => (
  <TextField
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    // This seems like the only way to get Chrome to not use autocomplete
    autoComplete="new-password"
  />
);

/**
 * Get available roles for the user role
 *
 * @param {string} userRole - a user role string
 * @returns {string[]} - array of of user roles
 */
const getRoles = (userRole) => {
  if (userRole === 'Admin') {
    return ROLE_NAMES;
  }
  if (userRole === 'Lab Manager') {
    return ['Researcher'];
  }
  return [];
};

/**
 * A form used to register a new user.
 *
 * @param {Object} props
 * @param {Object} props.user - The logged-in user who is registering a new user
 */
const RegisterForm = ({ user, handleClose, handlePostSubmit }) => {
  const classes = useStyles();
  const roles = getRoles(user.role);

  const handleSubmit = async (newUser, { setFieldError, setSubmitting }) => {
    // TODO: capture and validate the response
    try {
      await registerUser(
        newUser.firstname,
        newUser.lastname,
        newUser.username,
        newUser.password,
        newUser.role,
        'bel',
      );
      await handlePostSubmit();
    } catch (err) {
      if (err.response && err.response.status === 409) {
        setFieldError('username', 'Username already in use');
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        firstname: '',
        lastname: '',
        username: '',
        password: '',
        role: 'Researcher',
      }}
      validationSchema={RegisterUser}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Field
            component={TextFieldNoAutofill}
            name="firstname"
            label="First Name"
            required
            fullWidth
            margin="normal"
          />
          <Field
            component={TextFieldNoAutofill}
            name="lastname"
            label="Last Name"
            required
            fullWidth
            margin="normal"
          />
          <Field
            component={TextFieldNoAutofill}
            name="username"
            label="Username"
            required
            fullWidth
            margin="normal"
          />
          <Field
            component={TextFieldNoAutofill}
            name="password"
            label="Password"
            required
            fullWidth
            margin="normal"
            inputProps={{ className: classes.password }}
          />
          <FormControl
            fullWidth
            margin="normal"
          >
            <InputLabel>Role</InputLabel>
            <Field
              component={Select}
              name="role"
              label="Role"
              required
              fullWidth
              margin="normal"
            >
              {roles.map((role) => (
                <MenuItem key={role} value={role}>{role}</MenuItem>
              ))}
            </Field>
          </FormControl>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={submitForm} disabled={isSubmitting} color="primary">
              Register
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

RegisterForm.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handlePostSubmit: PropTypes.func.isRequired,
};

export default RegisterForm;
