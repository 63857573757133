import React from 'react';
import PropTypes from 'prop-types';
import { calcTimeDelta } from 'react-countdown';

/**
 * RemainingTimeIndicator
 *
 * renders a text string showing remaining time
 *
 * @param {number} value - remaining time in milliseconds
 * @returns {React.Component} - returns a text element in the following
 * format: "HH hrs MM min SS sec left". If the passed value is 0, the
 * returned text is: "Completing upload...".
 */
function RemainingTimeIndicator({ value }) {
  const { hours, minutes, seconds } = calcTimeDelta(Date.now() + value);
  let text = '';
  text += hours > 0 ? `${hours} hrs ` : '';
  text += minutes > 0 ? `${minutes} min ` : '';
  text += seconds > 0 ? `${seconds} sec ` : '';
  text += !text ? 'Completing upload...' : 'left';
  return <span>{text}</span>;
}

RemainingTimeIndicator.propTypes = {
  value: PropTypes.number.isRequired,
};

export default RemainingTimeIndicator;
