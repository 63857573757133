import http from './httpService';

const baseUrl = '/api/neat';

/**
 * returns model versions from the backend
 *
 * @returns {string[]} - a list of models
 */
async function getAvailableModels() {
  const url = `${baseUrl}/models`;
  const response = await http.get(url);
  return response.data;
}

export {
  getAvailableModels,
};
