/**
 * @fileOverview Functionality to call the login endpoint.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

import jwtDecode from 'jwt-decode';
import http from './httpService';
import License, { NoFreeLicensesError } from './license';

const baseUrl = '/api/auth';
const tokenKey = 'vt_token';

export function logout() {
  localStorage.removeItem(tokenKey);
}

export async function getFreeFlowLicense() {
  const license = new License();
  try {
    await license.reserve('flow');
  } catch (e) {
    if (e instanceof NoFreeLicensesError) {
      logout();
      alert('no free licenses!');
    } else {
      throw e;
    }
  }
  return license;
}

/**
 * Calls the login endpoint.
 * @param {string} username The user's username.
 * @param {string} password The user's password.
 */
export async function login(username, password) {
  const { data: jwt } = await http.post(baseUrl, { username, password });
  localStorage.setItem(tokenKey, jwt);
  http.setJWT(jwt);
  await getFreeFlowLicense();
}

export function getCurrentUser() {
  const jwt = localStorage.getItem(tokenKey);
  try {
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJWT() {
  return localStorage.getItem(tokenKey);
}

// Set the header for logged in users.
http.setJWT(getJWT());

export default {
  login,
  logout,
  getJWT,
  getCurrentUser,
};
