import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import MaterialTable from '@material-table/core';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import ExperimentFileUpload from './upload/ExperimentFileUpload';
import WorkflowAction from './WorkflowAction';
import { maybeTruncateString } from '../services/utils';
import { downloadExperimentFile, deleteExperimentFile } from '../services/experiments';
import { getWorkflowInfo, getHistoryInfo, filenameFromUrl } from '../services/files';
import ConfirmDialog from './modal/ConfirmDialog';
import FileInfoDialog from './modal/FileInfoDialog';
import { colors } from '../colorscheme';
import SvgIcon from '../icons';

/**
 * handler for detecting browser or
 * tab closing and alerting the user.
 *
 * @param {event} event - current event
 */
function handleBeforeUnload(event) {
  event.preventDefault();
  if ('returnValue' in event) {
    // legacy method for Chrome support
    event.returnValue = '';
  }
}

/**
 * @callback callback
 */

/**
 * ExperimentFiles component
 *
 * @param {object} props - component properties
 * @param {string} props.username - username of logged in user
 * @param {string} props.experimentId - experimentId of viewed experiment
 * @param {object[]} props.patients - list of patients in experiment
 * @param {object[]} props.files - list of files in experiment
 * @param {boolean} props.allowDelete - if true, allow deletion of experiment files
 * @param {callback} props.refresh - callback to update upon file upload
 */
const ExperimentFiles = ({
  username,
  experimentId,
  patients,
  files,
  allowDelete,
  refresh,
  onWorkflowUpdate,
}) => {
  const [showUpload, setShowUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);

  // configuration for the confirm dialog spawned when downloading files
  const [confirmDownloadOpen, setConfirmDownloadOpen] = useState(false);
  const [fileToDownload, setFileToDownload] = useState({});
  const [downloadMessage, setDownloadMessage] = useState('');

  // configuration for the confirm dialog spawned when deleting files
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [rowToDelete, setRowToDelete] = useState({});
  const [fileInfo, setFileInfo] = useState({});
  const [fileInfoOpen, setFileInfoOpen] = useState(false);

  useEffect(() => {
    if (isUploading) {
      window.addEventListener('beforeunload', handleBeforeUnload, { capture: true });
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true });
    }
  }, [isUploading]);

  useEffect(() => {
    const options = { capture: true };
    if (isDownloading) {
      window.addEventListener('beforeunload', handleBeforeUnload, options);
    }
    return () => window.removeEventListener('beforeunload', handleBeforeUnload, options);
  }, [isDownloading]);

  const handleDownload = async ({ url, uuid }) => {
    setConfirmDownloadOpen(false);
    const fullName = filenameFromUrl(url);
    const name = maybeTruncateString(fullName, 25);
    const style = { 'padding-top': '15px' };
    const paragraph = (text) => <p style={style} title={fullName}>{text}</p>;
    const options = {
      theme: 'dark',
      style: {
        color: 'white',
        fontWeight: 'bold',
        background: colors.ExternalFrame,
      },
    };
    const content = paragraph(`Preparing download: ${name}`);
    const toastId = toast.loading(content, options);
    setIsDownloading(true);
    try {
      await downloadExperimentFile(experimentId, uuid);
      toast.dismiss(toastId);
    } catch {
      toast.update(
        toastId,
        {
          render: paragraph(`Unable to download file: ${name}`),
          type: 'error',
          isLoading: false,
          closeButton: true,
        },
      );
    }
    setIsDownloading(false);
  };

  const handleDelete = async ({ url, uuid }) => {
    const fullName = filenameFromUrl(url);
    const name = maybeTruncateString(fullName, 25);
    setConfirmDeleteOpen(false);
    const style = { 'padding-top': '15px' };
    const paragraph = (text) => <p style={style} title={fullName}>{text}</p>;
    await toast.promise(
      deleteExperimentFile(experimentId, uuid),
      {
        pending: { render: paragraph(`Deleting file: ${name}`) },
        success: { render: paragraph(`File deleted: ${name}`), autoClose: 10000 },
        error: { render: paragraph(`Unable to delete file: ${name}`) },
      },
    );
    await refresh();
  };

  const handleViewFileInfo = async (index) => {
    const file = files[index];
    const workflowInfo = await getWorkflowInfo(experimentId, file.uuid);
    const historyInfo = await getHistoryInfo(experimentId, file.uuid);
    setFileInfo({ file, workflowInfo, historyInfo });
    setFileInfoOpen(true);
  };

  const handleUpload = () => {
    setIsUploading(true);
  };

  const handleSubmit = async () => {
    setIsUploading(false);
    setShowUpload(false);
    setSelectedPatient('');
    await refresh();
  };

  const handleCloseUploadFileDialog = () => {
    // TODO: add ability to cancel file uploads.
    // Prevent "Add file" dialog from closing while uploading files.
    if (!isUploading) {
      setShowUpload(false);
    }
  };

  const columns = [
    {
      title: 'Filename',
      field: 'url',
      render: ({ url, type }) => {
        const name = filenameFromUrl(url);
        return (
          <Tooltip title={type === 'directory' ? `All the files inside
            "${name}" were uploaded, but they are not shown in FLOW` : ''}
          >
            <span>{name}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Upload Date',
      field: 'date',
      render: (file) => new Date(file.date).toLocaleString(),
      defaultSort: 'desc',
    },
    { title: 'Participant', field: 'patientId', defaultGroupOrder: 0 },
    { title: 'Type', field: 'type' },
    {
      title: 'Status',
      field: 'status',
      render: ({ details, status }) => (
        <Tooltip title={details}>
          <span>{status}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Workflows',
      render: (file) => (
        <WorkflowAction
          disabled={['error', 'processing'].includes(file.status)}
          tooltip={file.details}
          filename={file.url.substring(file.url.indexOf('::') + 2, file.url.length)}
          fileType={file.type}
          patientId={file.patientId}
          age={patients.find((it) => it.patientId === file.patientId).age}
          fileId={file.uuid}
          experimentId={experimentId}
          username={username}
          refresh={refresh}
          onWorkflowUpdate={onWorkflowUpdate}
        />
      ),
      sorting: false,
    },
  ];

  const actions = [
    {
      icon: SvgIcon({ name: 'DownloadFile', width: 24 }),
      tooltip: 'Download file',
      onClick: async (event, rowData) => {
        const file = filenameFromUrl(rowData.url);
        setDownloadMessage(`This action will be recorded. Are you sure you want to download '${file}'?`);
        setFileToDownload(rowData);
        setConfirmDownloadOpen(true);
      },
    },
    (rowData) => ({
      icon: SvgIcon({ name: 'Info', width: 24, disabled: rowData.type !== 'mff' }),
      tooltip: 'View file info',
      onClick: (event, { tableData }) => {
        handleViewFileInfo(tableData.id);
      },
      disabled: rowData.type !== 'mff',
    }),
  ];
  if (allowDelete) {
    actions.push({
      icon: SvgIcon({ name: 'Delete', width: 18 }),
      tooltip: 'Delete',
      onClick: async (event, row) => {
        const filename = filenameFromUrl(row.url);
        setDeleteMessage(`Are you sure you want to delete '${filename}'? This action will be recorded.`);
        setRowToDelete(row);
        setConfirmDeleteOpen(true);
      },
    });
  }

  return (
    <>
      <br />
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        onClick={() => setShowUpload((upload) => !upload)}
      >
        Add Files
      </Button>
      <br />
      <br />
      <Dialog
        open={showUpload}
        onClose={handleCloseUploadFileDialog}
        fullWidth
      >
        <DialogTitle>Add files</DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
          >
            <InputLabel>Select participant</InputLabel>
            <Select
              value={selectedPatient}
              onChange={(event) => setSelectedPatient(event.target.value)}
              fullWidth
              margin="normal"
            >
              {patients.map(({ patientId }) => (
                <MenuItem
                  key={patientId}
                  value={patientId}
                >
                  {patientId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <br />
          {selectedPatient && (
            <ExperimentFileUpload
              username={username}
              experimentId={experimentId}
              patientId={selectedPatient}
              onUpload={handleUpload}
              onSubmit={handleSubmit}
            />
          )}
        </DialogContent>
      </Dialog>
      <MaterialTable
        title="Experiment Files"
        isLoading={isDownloading}
        columns={columns}
        data={files}
        actions={actions}
        editable={{
          isDeletable: () => allowDelete,
        }}
        options={{
          sorting: true,
          paging: false,
          grouping: true,
          padding: 'dense',
          maxBodyHeight: '60vh',
          headerStyle: { position: 'sticky', top: 0, paddingRight: '4vw' },
        }}
        components={{
          Groupbar: () => '',
        }}
      />
      <ConfirmDialog
        title="Download file"
        message={downloadMessage}
        open={confirmDownloadOpen}
        setOpen={setConfirmDownloadOpen}
        onConfirm={() => handleDownload(fileToDownload)}
      />
      <ConfirmDialog
        title="Delete file"
        message={deleteMessage}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={() => handleDelete(rowToDelete)}
      />
      <FileInfoDialog
        open={fileInfoOpen}
        info={fileInfo}
        onClose={() => setFileInfoOpen(false)}
      />
    </>
  );
};

ExperimentFiles.propTypes = {
  username: PropTypes.string.isRequired,
  experimentId: PropTypes.string.isRequired,
  patients: PropTypes.arrayOf(PropTypes.shape({ patientId: PropTypes.string })).isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    url: PropTypes.string,
    date: PropTypes.string,
    patientName: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
  })).isRequired,
  allowDelete: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default ExperimentFiles;
