import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  Button,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { getJWT } from '../services/authService';
import http from '../services/httpService';
import ConvertToMffMenuItem from './ConvertToMffMenuItem';
import SourcererMenuItem from './SourcererMenuItem';
import ErpMenuItem from './ErpMenuItem';
import NeatMenuItem from './NeatMenuItem';
import { checkMffSupport } from '../services/files';
import License, { NoFreeLicensesError } from '../services/license';

const WorkflowAction = ({
  disabled,
  tooltip,
  filename,
  fileType,
  patientId,
  age,
  fileId,
  experimentId,
  username,
  refresh,
  onWorkflowUpdate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [features, setFeatures] = useState({});
  const [mffWorkflows, setMffWorkflows] = useState({});
  const license = new License();

  const updateLicense = async () => {
    await license.update();
    setFeatures(license.features);
  };

  const getMffSupport = (typ) => {
    if (typ in mffWorkflows) {
      return mffWorkflows[typ];
    }
    return { isSupported: false, reason: 'Unable to check mff support' };
  };

  useEffect(() => { updateLicense(); }, []);
  useEffect(() => {
    if (fileType === 'mff') {
      checkMffSupport({ experimentId, patientId, fileId })
        .then(setMffWorkflows)
        .catch(() => setMffWorkflows({}));
    }
  }, [experimentId, patientId, fileId, fileType]);

  /**
   * map mff channel numbers to actual channel numbers
   *
   * TODO: .mff files with 256 channel systems store 257 channels.  The
   * additional channel is probably the reference channel, but we want to confirm
   * that first.
   */
  const CHANNELS_MAP = {
    33: 32,
    65: 64,
    129: 128,
    131: 130,
    257: 256,
    281: 280,
  };

  const handleWorkflowFinish = async (workflowId) => {
    if (workflowId) {
      const { data: workflows } = await axios.get(`/api/workflow/active/${username}`);
      const activeWorkflow = workflows.find((workflow) => workflow.url === workflowId);
      if (activeWorkflow && activeWorkflow.paintedStatus === 'Error') {
        alert(`An error has ocurred while running workflow "${workflowId}"`);
      }
    }
    await refresh();
    onWorkflowUpdate();
  };

  const handleEmfavRedirect = async () => {
    if (!age) {
      throw new Error(`Patient ${patientId} is missing age`);
    }

    const entityId = experimentId || patientId;
    const route = `/api/files/mff/info/${entityId}/${fileId}`;

    const { data: fileInfo } = await http.get(route);
    let channels = fileInfo.eegNumberOfChannels;
    channels = CHANNELS_MAP[channels] || channels;
    const emfavConstants = JSON.parse(localStorage.getItem('emfav_flow_constants')) || {};
    let { FLOW_LICENSE: sourcererLicenseId = null } = emfavConstants;
    const validLicense = sourcererLicenseId && await License.valid(sourcererLicenseId);
    if (!validLicense) {
      try {
        await license.reserve('sourcerer');
        sourcererLicenseId = license.token;
      } catch (e) {
        if (e instanceof NoFreeLicensesError) {
          toast.error('No free Sourcerer licenses');
        } else {
          toast.error(e.message);
        }
        return;
      }
    }
    const state = {
      HEAD_MODEL_IS_AN_ATLAS: true,
      FLOW_URL: window.location.origin,
      FLOW_EXIT_SESSION_URL: '/',
      FLOW_TOKEN: getJWT(),
      FLOW_LICENSE: sourcererLicenseId,
      FLOW_PATIENT_ID: experimentId || patientId,
      FLOW_FILEID: fileId,
      FLOW_AGE: age,
      FLOW_CHANNELS: channels,
      EMIM_INVERSE_ENDPOINT: 'FLOW',
      INIT_FROM_SECRETS_FILE: false,
    };
    window.localStorage.setItem('emfav_flow_constants', JSON.stringify(state));
    window.open('/emfav/eav.html', '_blank');
  };

  const handleBelViewRedirect = async () => {
    const entityId = experimentId || patientId;
    const state = {
      FLOW_TOKEN: getJWT(),
      FLOW_PATIENT_ID: entityId,
      FLOW_FILEID: fileId,
      FLOW_URL: '',
    };
    window.localStorage.setItem('belView_flow_constants', JSON.stringify(state));
    window.open('/belView/index.html', '_blank');
  };

  /**
   * Handle the conversion of an .edf file to .mff format
   *
   * @param {string} sensornet - one of the supported
   * sensornets for the edf2mff workflow.
   * @param {string} timezone - a time zone
   */
  const handleEdf2Mff = async (sensornet, timezone) => {
    if (sensornet && timezone) {
      const workflowArgs = {
        experimentId,
        patientId,
        type: 'edf2mff',
        name: filename,
        fileIds: [fileId],
        sensornet,
        timezone,
      };
      try {
        onWorkflowUpdate();
        const submitUrl = '/api/workflow/submit';
        const { data: workflowId } = await http.post(submitUrl, workflowArgs);
        handleWorkflowFinish(workflowId);
      } catch ({ response }) {
        const message = response?.data?.message || 'Unexpected error while '
        + `running "Convert to MFF" workflow from file: ${fileId}`;
        toast.error(message);
        onWorkflowUpdate();
      }
    }
  };

  return ['mff', 'edf', 'bdf'].includes(fileType) ? (
    <>
      <Tooltip title={tooltip}>
        <span>
          <Button
            disabled={disabled}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
            color="primary"
            size="small"
            style={{
              paddingTop: '1px',
              paddingBottom: '1px',
            }}
          >
            Workflow
          </Button>
        </span>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <ConvertToMffMenuItem
          onClick={() => setAnchorEl(null)}
          onClose={handleEdf2Mff}
          fileProps={{
            fileId,
            fileType,
            entityId: experimentId !== null ? experimentId : patientId,
          }}
        />
        {
          features.sourcerer
          && (
            <SourcererMenuItem
              disabled={!getMffSupport('sourcerer').isSupported}
              title={getMffSupport('sourcerer').reason}
              onClick={() => {
                setAnchorEl(null);
                handleEmfavRedirect();
              }}
              fileType={fileType}
            />
          )
}
        {
          features.belview
          && fileType === 'mff'
          && (
          <Tooltip title={getMffSupport('bel-view').reason}>
            <div>
              <MenuItem
                disabled={!getMffSupport('bel-view').isSupported}
                onClick={() => {
                  setAnchorEl(null);
                  handleBelViewRedirect();
                }}
              >
                BEL View
              </MenuItem>
            </div>
          </Tooltip>
          )
}
        {
          features.erp
          && experimentId !== null
          && (
            <ErpMenuItem
              disabled={!getMffSupport('erp').isSupported}
              title={getMffSupport('erp').reason}
              onClick={() => setAnchorEl(null)}
              onWorkflowStart={onWorkflowUpdate}
              onWorkflowFinish={handleWorkflowFinish}
              onWorkflowError={onWorkflowUpdate}
              fileProps={{
                experimentId,
                patientId,
                filename,
                fileId,
                fileType,
              }}
            />
          )
        }
        {
          features.neat
          && experimentId !== null
          && (
            <NeatMenuItem
              disabled={!getMffSupport('neat').isSupported}
              title={getMffSupport('neat').reason}
              onClick={() => setAnchorEl(null)}
              onWorkflowStart={onWorkflowUpdate}
              onWorkflowFinish={handleWorkflowFinish}
              onWorkflowError={onWorkflowUpdate}
              fileProps={{
                experimentId,
                patientId,
                filename,
                fileId,
                fileType,
              }}
            />
          )
        }
      </Menu>
    </>
  ) : (
    'N/A'
  );
};

WorkflowAction.propTypes = {
  disabled: PropTypes.bool.isRequired,
  filename: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  age: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  experimentId: PropTypes.string,
  username: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

WorkflowAction.defaultProps = {
  experimentId: null,
};

export default WorkflowAction;
