import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import http from '../services/httpService';

const baseUrl = '/api/workflow';

/**
 * ActiveWorkflows
 *
 * renders a table to list active workflows
 *
 * @param {object} props - component properties
 * @param {object} props.user - user object
 * @returns {React.Component} - returns a material table component
 */
const ActiveWorkflows = (props) => {
  const [workflows, setWorkflows] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);

  const fetchData = async () => {
    const url = `${baseUrl}/active/${props.user.username}`;
    const { data } = await http.get(url);
    return data;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdateCount((count) => count + 1);
    }, 10 * 1000);
    return () => {
      console.log('Clearing timer!');
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    fetchData().then((data) => {
      setWorkflows(data);
    });
  }, [props.user.username, updateCount]);

  const columns = [
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => {
        const { name } = rowData;
        return (
          <div style={{ width: 300, whiteSpace: 'nowrap' }}>
            <Box overflow="auto" bgcolor="background.paper">
              {name}
            </Box>
          </div>
        );
      },
    },
    {
      title: 'Type',
      field: 'type',
    },
    // Change participant to experiment?
    { title: 'Participant', field: 'patient' },
    { title: 'Status', field: 'status' },
    // By default, sort workflows by newest-first
    {
      title: 'Date Started',
      field: 'date',
      render: (workflow) => new Date(workflow.date).toLocaleString(),
      defaultSort: 'desc',
    },
  ];

  return (
    <MaterialTable
      title="Active Workflows"
      columns={columns}
      data={
        workflows.map((workflow) => ({
          name: workflow.name,
          id: workflow._id,
          type: workflow.type,
          url: workflow.url,
          patient: workflow.patientID,
          status: workflow.paintedStatus,
          date: workflow.dateStarted,
          logs: workflow.logs,
        }))
      }
      options={{
        paging: false,
      }}
    />
  );
};

ActiveWorkflows.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

export default ActiveWorkflows;
