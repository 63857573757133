/**
 * @fileOverview Workflow component.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import Attachment from '@material-ui/icons/Attachment';
import SettingsIcon from '@material-ui/icons/Settings';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// FLOW
import EnhancedTable from './EMADSelectFiles';
import SubmitWorkflow from './SubmitWorkflow';
import Options from './Options';
import FinishedWorkflow from './FinishedWorkflow';
import http from '../services/httpService';

const baseUrl = '/api/workflow/submit';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

/* eslint-disable */
QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};
/* eslint-enable */

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <Attachment />,
    2: <SettingsIcon />,
    3: <PlayCircleOutline />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

/* eslint-disable */
ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};
/* eslint-enable */

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Select EMAD file(s)...', 'Options', 'Go!'];
}

function getStepContent(step, patient, classes, setFileIds) {
  switch (step) {
    case 0:
      return (
        <div>
          <EnhancedTable patient={patient} setFileIds={setFileIds} />
        </div>
      );
    case 1:
      return (
        <div>
          <Options />
        </div>
      );
    case 2:
      return (
        <div>
          <SubmitWorkflow />
        </div>
      );
    default:
      return (
        <Typography className={classes.instructions}>Unknown step</Typography>
      );
  }
}

export default function CustomizedSteppers(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [fileIds, setFileIds] = React.useState([]);
  const steps = getSteps();

  function handleNext() {
    if (activeStep === steps.length - 1) {
      // Todo: Error checking!!!
      // Submit the job!
      const type = 'EMAD';
      const { username } = props.user;
      const patientID = props.patient.medicalID;

      console.log(
        JSON.stringify({
          type,
          username,
          patientID,
        }),
      );

      // Please note:
      // We still do NOT have any ability to / knowledge of:
      // 1) Which files we are submitting.
      // 2) Which options to set. We know there will be some environment
      // variables, but until we can complete a workflow, there is no
      // point adding the information.
      // 3) Knowlege of which files are returned to the DB. This should not
      // be a concern of the UI so much, but the backend DB will need to know.
      // 4) Ability to even run the segmentation algorithm (let alone the physics
      // code).
      //
      // All of the above will have to be complete before it makes much sense to
      // work on details. At present, the front and backends of flow simply start
      // a container with some basica information, and the container scripts run
      // test data inputs. The final solution will involve a lot more.
      //
      // This comment is in the front and backends.
      http.post(baseUrl, {
        type,
        patientID,
        fileIds,
      });
    }

    // setActiveStep();

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function handleReset() {
    setActiveStep(0);
  }

  return (
    <div>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <FinishedWorkflow />
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            {getStepContent(activeStep, props.patient, classes, setFileIds)}
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Submit Workflow!' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
