import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import http from '../services/httpService';

const baseUrl = '/api/workflow';

/**
 * WorkflowHistory
 *
 * renders a table to list finished workflows
 *
 * @param {object} props - component properties
 * @param {object} props.user - user object
 * @returns {React.Component} - returns a material table component
 */
const WorkflowHistory = ({ user }) => {
  const [workflows, setWorkflows] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);

  const fetchData = async () => {
    const url = `${baseUrl}/finished`;
    const { data } = await http.get(url);
    return data;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdateCount((count) => count + 1);
    }, 10 * 1000);
    return () => {
      console.log('Clearing timer!');
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    fetchData().then((data) => {
      setWorkflows(data);
    });
  }, [user.username, updateCount]);

  const columns = [
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => {
        const { name } = rowData;
        return (
          <div style={{ width: 300, whiteSpace: 'nowrap' }}>
            <Box overflow="auto" bgcolor="background.paper">
              {name}
            </Box>
          </div>
        );
      },
    },
    {
      title: 'Type',
      field: 'type',
    },
    // Change participant to experiment?
    { title: 'Participant', field: 'patient' },
    {
      title: 'Username',
      field: 'username',
      hidden: !['Admin', 'Lab Manager'].includes(user.role),
    },
    { title: 'Status', field: 'status' },
    // By default, sort workflows by newest-first
    {
      title: 'Date Finished',
      field: 'date',
      render: (workflow) => new Date(workflow.date).toLocaleString(),
      defaultSort: 'desc',
    },
  ];

  return (
    <MaterialTable
      title="Workflow History"
      columns={columns}
      data={
        workflows.map((workflow) => ({
          name: workflow.name,
          id: workflow._id,
          type: workflow.type,
          url: workflow.url,
          patient: workflow.patientID,
          username: workflow.username,
          status: workflow.paintedStatus,
          date: workflow.dateFinished,
          logs: workflow.logs,
        }))
      }
      detailPanel={({ rowData }) => {
        const style = {
          backgroundColor: '#EEE',
          padding: '10px',
          marginTop: '0',
          marginBottom: '0',
          marginRight: '0',
          marginLeft: '20px',
        };
        const json = {
          id: rowData.id,
          location: rowData.url,
        };
        return (
          <>
            <pre style={style}>{JSON.stringify(json, null, 2)}</pre>
            {rowData.status === 'Error' && <pre style={style}>{rowData.logs}</pre>}
          </>
        );
      }}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
      options={{
        pageSize: 20,
        pageSizeOptions: [20, 30, 50],
      }}
    />
  );
};

WorkflowHistory.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

export default WorkflowHistory;
