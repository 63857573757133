/**
 * @fileOverview Provides a display of active workflows.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import { Select as FormikSelect, TextField } from 'formik-material-ui';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import MaterialTable, { MTableAction } from '@material-table/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import CloudUpload from '@material-ui/icons/CloudUpload';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import DevicesIcon from '@material-ui/icons/Devices';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// FLOW
import { getDevices } from '../services/devices';
import { getAges, displayAges, dbAges } from '../services/ages';
import { getPatients, registerPatient, updatePatient } from '../services/patients';
import PatientFiles from './PatientFiles';
import DeviceLogs from './DeviceLogs';
import FileUpload from './FileUpload';
import CustomizedSteppers from './Workflow';
import License from '../services/license';

const AddPatientButton = styled(Button)({
  margin: '10px',
});

const Patient = (props) => {
  const { user } = props;
  const { medicalID, fileID } = useParams();
  const history = useHistory();

  const patientSchema = Yup.object().shape({
    firstname: Yup.string()
      .required()
      .max(50)
      .label('First Name'),
    lastname: Yup.string()
      .required()
      .max(50)
      .label('Last Name'),
    age: Yup.string()
      .required()
      .label('Age'),
    deviceID: Yup.string()
      .max(50)
      .label('Device ID'),
  });

  const AddPatientSchema = Yup.object().shape({
    firstname: Yup.string()
      .required()
      .max(50)
      .label('First Name'),
    lastname: Yup.string()
      .required()
      .max(50)
      .label('Last Name'),
    medicalID: Yup.string()
      .matches(/^[a-zA-Z0-9][a-zA-Z0-9_-]*$/, 'begins with [a-zA-Z0-9] followed by [a-zA-Z0-9_-]*')
      .required()
      .min(5)
      .max(50)
      .label('Medical ID'),
    age: Yup.string()
      .matches(/^[a-zA-Z0-9][a-zA-Z0-9_-]*$/, 'begins with [a-zA-Z0-9] followed by [a-zA-Z0-9_-]*')
      .required()
      .label('Age'),
    deviceID: Yup.string()
      .matches(/^[a-zA-Z0-9][a-zA-Z0-9_-]*$/, 'begins with [a-zA-Z0-9] followed by [a-zA-Z0-9_-]*')
      .min(5)
      .max(50)
      .label('Device ID'),
  });

  const patientValidator = (field) => (rowData) => {
    try {
      Yup.reach(patientSchema, field).validateSync(rowData[field]);
      return { isValid: true, helperText: '' };
    } catch (e) {
      return { isValid: false, helperText: e.message };
    }
  };

  const [activePatient, setActivePatient] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [agesLookup, setAgesLookup] = useState({});
  // `defaultAge` is set as age default when creating patients
  const [defaultAge, setDefaultAge] = useState('');
  const [devices, setDevices] = useState([]);
  const [features, setFeatures] = useState(License.defaults());

  /* update features from license service */
  useEffect(() => {
    const license = new License();
    license.update()
      .then(() => setFeatures(license.features));
  }, []);

  const fetchDevices = useCallback(async () => {
    const newDevices = await getDevices({ unowned: true, disabled: false });
    setDevices(newDevices);
  }, []);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  /**
   * Get the current Device ID for a table row. Used when editing an existing row
   *
   * @param {object} componentProps - Props for the edit component function
   * @return {object|null} MenuItem component if deviceID is specified, null otherwise
   */
  const getCurrentDeviceID = (componentProps) => {
    const { deviceID } = tableData.find((i) => i.medicalID === componentProps.rowData.medicalID);
    return deviceID ? <MenuItem key={deviceID} value={deviceID}>{deviceID}</MenuItem> : null;
  };

  const columns = [
    { title: 'First Name', field: 'firstname', validate: patientValidator('firstname') },
    { title: 'Last Name', field: 'lastname', validate: patientValidator('lastname') },
    {
      title: 'Age',
      field: 'age',
      validate: patientValidator('age'),
      lookup: agesLookup,
    },
    { title: 'Medical ID', field: 'medicalID', editable: 'onAdd' },
    { title: 'Clinician', field: 'clinician', editable: 'onAdd' },
  ];

  if (features.devices) {
    const deviceIdColumn = {
      title: 'Device ID',
      field: 'deviceID',
      validate: patientValidator('deviceID'),
      editComponent: (componentProps) => (
        <Select
          fullWidth
          value={componentProps.value}
          onChange={(e) => componentProps.onChange(e.target.value)}
        >
          <MenuItem>None</MenuItem>
          {getCurrentDeviceID(componentProps)}
          {devices.map(({ deviceId }) => (
            <MenuItem key={deviceId} value={deviceId}>{deviceId}</MenuItem>
          ))}
        </Select>
      ),
    };
    columns.splice(4, 0, deviceIdColumn);
  }

  /*
  Supported actions:
    - list_files: Lists all files associated with the patient.
    - upload_files: Displays a dropzone.
    - start_workflow: Displays the start workflow content.
  */
  const [selectedAction, setSelectedAction] = useState(null);

  const fetchData = useCallback(async () => {
    const patients = await getPatients();

    let updatedPatient = null;

    const data = patients.map((patient) => {
      // Build return value.
      patient.age = displayAges(patient.age);
      const rVal = {
        firstname: patient.firstname,
        lastname: patient.lastname,
        medicalID: patient.medicalID,
        age: patient.age,
        deviceID: patient.deviceID,
        clinician: patient.clinician,
      };

      // Check to see if we need to select an action
      // and assign patient object.
      if (patient.medicalID === medicalID) {
        updatedPatient = rVal;
        setSelectedAction('list_files');
      }

      return rVal;
    });

    setActivePatient(updatedPatient);
    setTableData(data);
  }, [medicalID]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Age categories
  const fetchAges = useCallback(async () => {
    const ages = await getAges();
    const thisDefaultAge = ages.includes('adult') ? displayAges('adult') : ages[0];
    const lookup = Object.fromEntries(ages.map((a) => [displayAges(a), displayAges(a)]));
    setDefaultAge(thisDefaultAge);
    setAgesLookup(lookup);
  }, []);

  useEffect(() => {
    fetchAges();
  }, [fetchAges]);

  const [addPatientDialogOpen, setAddPatientDialogOpen] = useState(false);

  const handleAddPatientDialogOpen = async () => {
    setAddPatientDialogOpen(true);
    // Update the devices
    await fetchDevices();
    // Update age categories
    await fetchAges();
  };
  const handleAddPatientDialogClose = () => setAddPatientDialogOpen(false);
  const handlePatientSubmit = async (data, { setFieldError, setSubmitting }) => {
    try {
      data.age = dbAges(data.age);
      await registerPatient(
        data.firstname,
        data.lastname,
        data.medicalID,
        user.username,
        data.age,
        data.deviceID || undefined,
      );
      await fetchData();
      handleAddPatientDialogClose();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        // TODO: handle this better
        if (err.response.data === 'Error: Patient ID already in use.') {
          setFieldError('medicalID', 'Patient ID already in use');
          setSubmitting(false);
        } else if (err.response.data === 'Error: Device ID already in use.') {
          setFieldError('deviceID', 'Device ID already in use');
          setSubmitting(false);
        } else {
          console.error(`unexpected error: ${err.response.data}`);
        }
      }
    }
  };

  function handleSelectAction(action, rowData) {
    setActivePatient({
      firstname: rowData.firstname,
      lastname: rowData.lastname,
      age: dbAges(rowData.age),
      medicalID: rowData.medicalID,
      deviceID: rowData.deviceID,
    });
    setSelectedAction(action);
  }

  function getActions() {
    const actions = [];

    actions.push({
      icon: '',
      tooltip: 'Add a participant',
      isFreeAction: true,
      onClick: () => handleAddPatientDialogOpen(),
    });

    actions.push({
      // Action
      // This action shows a listing of files associated
      // with the selected patient.
      icon: 'list',
      tooltip: 'Show Files',
      onClick: (event, rowData) => {
        handleSelectAction('list_files', rowData);
      },
    });

    if (user.userType === 'vt' && features.devices) {
      actions.push({
        // Action
        // This action shows a listing of files associated
        // with the selected patient.
        icon: DevicesIcon,
        tooltip: 'Device Logs',
        onClick: (event, rowData) => {
          handleSelectAction('device_logs', rowData);
        },
      });
    }

    actions.push({
      // Action
      // This action displays the file upload dropzone.
      icon: CloudUpload,
      tooltip: 'Upload Files',
      onClick: (event, rowData) => {
        handleSelectAction('upload_files', rowData);
      },
    });

    actions.push({
      // Action
      // This action displays the start workflow.
      icon: PlayCircleOutline,
      tooltip: 'Select Workflow',
      onClick: (event, rowData) => {
        handleSelectAction('start_workflow', rowData);
      },
    });

    return actions;
  }

  return (
    <div>
      <div>
        <MaterialTable
          title="Participants"
          columns={columns}
          data={tableData}
          onRowClick={(event, rowData) => {
            history.push(`/participants/${rowData.medicalID}/0`);
          }}
          options={{
            paging: false,
            rowStyle: (rowData) => ({
              backgroundColor:
                activePatient && activePatient.medicalID === rowData.medicalID
                  ? '#EEE'
                  : '#FFF',
            }),
            maxBodyHeight: '45vh',
          }}
          components={{
            Action: (componentProps) => {
              // Use our custom button for adding a patient
              if (componentProps.action.tooltip === 'Add a participant') {
                return (
                  <AddPatientButton
                    color="primary"
                    variant="contained"
                    onClick={(event) => componentProps.action.onClick(event, componentProps.data)}
                  >
                    Add a participant
                  </AddPatientButton>
                );
              }
              // Use the regular action component for inline actions
              return (
                <MTableAction
                  {...componentProps} // eslint-disable-line react/jsx-props-no-spreading
                />
              );
            },
          }}
          editable={{
            onRowUpdate: async (newData) => {
              try {
                await updatePatient(
                  newData.firstname,
                  newData.lastname,
                  newData.medicalID,
                  user.username,
                  newData.age,
                  newData.deviceID,
                );
                await Promise.all([fetchData(), fetchDevices()]);
              } catch (ex) {
                if (ex.response && ex.response.status === 400) {
                  alert(
                    `Received error response from server: ${
                      ex.response.data}`,
                  );
                }
              }
            },
          }}
          actions={getActions()}
        />
        <Dialog
          open={addPatientDialogOpen}
          onClose={handleAddPatientDialogClose}
          fullWidth
        >
          <DialogTitle>Add new participant</DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                firstname: '',
                lastname: '',
                medicalID: '',
                age: defaultAge,
                deviceID: '',
              }}
              validationSchema={AddPatientSchema}
              onSubmit={handlePatientSubmit}
            >
              {({ submitForm }) => (
                <Form>
                  <Field
                    component={TextField}
                    name="firstname"
                    label="First Name"
                    required
                    fullWidth
                    margin="normal"
                  />
                  <Field
                    component={TextField}
                    name="lastname"
                    label="Last Name"
                    required
                    fullWidth
                    margin="normal"
                  />
                  <Field
                    component={TextField}
                    name="medicalID"
                    label="Medical ID"
                    required
                    fullWidth
                    margin="normal"
                  />
                  {/* Selector for age categories */}
                  <FormControl
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel>Age Category</InputLabel>
                    <Field
                      component={FormikSelect}
                      name="age"
                      label="age"
                      labelWidth="10"
                    >
                      {
                        Object.keys(agesLookup).map((age) => (
                          <MenuItem key={age} value={age}>{age}</MenuItem>
                        ))
                      }
                    </Field>
                    {/* TODO: fix the styling for this error message */}
                    <ErrorMessage name="age" />
                  </FormControl>
                  {/* Selector for devices */}
                  {
                    features.devices
                    && (
                      <FormControl
                        fullWidth
                        margin="normal"
                      >
                        <InputLabel>Device ID</InputLabel>
                        <Field
                          component={FormikSelect}
                          name="deviceID"
                          label="Device ID"
                          labelWidth="10"
                        >
                          <MenuItem value="">None</MenuItem>
                          {
                            devices.map(({ deviceId }) => (
                              <MenuItem key={deviceId} value={deviceId}>{deviceId}</MenuItem>
                            ))
                          }
                        </Field>
                        {/* TODO: fix the styling for this error message */}
                        <ErrorMessage name="deviceID" />
                      </FormControl>
                    )
                  }
                  <DialogActions>
                    <Button onClick={handleAddPatientDialogClose} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={submitForm} color="primary">
                      Add
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        {/* Depending on the selected action, the appropriate content
        is displayed. Currently:
         - list_files: Lists all files associated with the patient.
         - upload_files: Displays a dropzone.
         - start_workflow: Displays the start workflow content. */}

        {selectedAction === 'list_files' && (
          <PatientFiles
            user={user}
            patient={activePatient}
            directoryId={fileID}
          />
        )}
        {selectedAction === 'upload_files' && (
          <>
            <br />
            <FileUpload
              user={user}
              patient={activePatient}
              fileID={fileID}
              postSubmit={() => setSelectedAction('list_files')}
            />
          </>
        )}
        {selectedAction === 'device_logs' && (
          <>
            <br />
            <DeviceLogs patient={activePatient} />
          </>
        )}
        {selectedAction === 'start_workflow' && (
          <CustomizedSteppers
            user={user}
            patient={activePatient}
            fileID={fileID}
          />
        )}
      </div>
    </div>
  );
};

Patient.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    userType: PropTypes.string,
  }).isRequired,
};

export default Patient;
