/**
 * @fileOverview Wrapper around file upload.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React, { Component } from 'react';

// FLOW
import '../css/FileUpload.css';
import Upload from './upload/Upload';
/* eslint-disable */
class FileUpload extends Component {
  render() {
    return (
      <div className="FileUpload">
        <div className="Card">
          {/* Not particularly sure I like passing all the props here! */}
          <Upload {...this.props} />
        </div>
      </div>
    );
  }
}
/* eslint-enable */
export default FileUpload;
