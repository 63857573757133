import * as Yup from 'yup';

const AddDeviceSchema = Yup.object().shape({
  deviceId: Yup.string()
    .required()
    .min(5)
    .test(
      'no-periods',
      'Periods are not allowed',
      (id) => !id.includes('.'),
    )
    .label('Device ID'),
  deviceName: Yup.string()
    .label('Device Name'),
  deviceType: Yup.string()
    .label('Device Type'),
  metadata: Yup.object().shape({
    wispFirmwareVersion: Yup.string()
      .required()
      .label('WISP Firmware Version'),
    nanoImageVersion: Yup.string()
      .required()
      .label('Nano Image Version'),
    dongleFirmwareVersion: Yup.string()
      .required()
      .label('Dongle Firmware Version'),
  }),
});

export { AddDeviceSchema };
