/**
 * @fileOverview Functionality to call the user registration endpoint.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

import http from './httpService';

const baseUrl = '/api/users';

/**
 * Queries '/api/users' for all users
 *
 * @async
 * @function getUsers
 * @returns {object[]} - list of all users
 */
const getUsers = async () => {
  const { data: users } = await http.get(baseUrl);
  return users;
};

/**
 * Calls the user registration endpoint.
 * @param {string} firstname First name.
 * @param {string} lastname Last name.
 * @param {string} username Username.
 * @param {string} password Password.
 * @param {string} role The user's role.
 * @param {string} userType The user's type.
 */
const registerUser = (
  firstname,
  lastname,
  username,
  password,
  role,
  userType,
) => http.post(baseUrl, {
  firstname,
  lastname,
  username,
  password,
  role,
  userType,
});

const patchUser = async (username, updateBody) => {
  await http.patch(`${baseUrl}/${username}`, updateBody);
};

const deleteUser = async (username) => http.delete(`${baseUrl}/${username}`);

export {
  getUsers,
  registerUser,
  patchUser,
  deleteUser,
};
