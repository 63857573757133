/**
 * @fileOverview Provides general HTTP services.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

import axios from 'axios';
import { toast } from 'react-toastify';

const errorReportsUrl = '/api/errorReports';
const authUrl = '/api/auth';

/**
 * Set the JWT in axios default headers
 *
 * @param {string} jwt - The JWT
 */
function setJWT(jwt) {
  // Set the header for logged in users.
  axios.defaults.headers.common['x-auth-token'] = jwt;
}

// Request interceptor that grabs JWT from local Storage
// and sends it with request. Just in case it was modified.
axios.interceptors.request.use((req) => {
  // Avoid setting JWT if login in
  if (req.url === authUrl) {
    return req;
  }
  // Set JWT again in case it has been tampered with
  const token = localStorage.getItem('vt_token');
  setJWT(token);
  return req;
});

axios.interceptors.response.use(null, async (error) => {
  const { url, method, headers } = error.response.config;
  const tokenHeader = headers['x-auth-token'];
  const hasToken = typeof tokenHeader === 'string' && tokenHeader !== '';
  // Handle the error by attempting to send it to the backend, but only if the failed request was
  // not itself handling another request. Otherwise, this would cause an infinite loop.
  if (!(url === errorReportsUrl && method === 'post') && hasToken) {
    // If a route would normally return binary data, then it will parse error JSON as a Blob by
    // default. We need to convert it back to JSON.
    if (error.response.data instanceof Blob && error.response.data.type === 'application/json') {
      error.response.data = JSON.parse(await error.response.data.text());
    }
    await axios.post(
      errorReportsUrl,
      {
        source: 'FLOW frontend',
        message: JSON.stringify(error.response),
      },
    );
  }

  const expectedError = error.response
    && error.response.status >= 400
    && error.response.status < 500;

  if (!expectedError) {
    console.log('HTTP response error: ', error);
    toast.error('An unexpected error has occured!');
  }

  // Redirect in the case of invalid JWT
  if (error?.response?.status === 401) {
    localStorage.clear();
    window.location.href = '/login';
  }

  throw error;
});

// Shield Axios behind the below exports.
export default {
  get: axios.get,
  patch: axios.patch,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJWT,
};
