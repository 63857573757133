const schema = {
  Button: '#B37F40',
  Background: '#F2F2F2',
  ExternalFrame: '#02728A',
};

const colors = {
  AppBar: schema.ExternalFrame,
  Button: '#B37F40',
  Background: '#F2F2F2',
  ExternalFrame: '#02728A',
};

export {
  colors,
};
