import http from './httpService';

const baseUrl = '/api/files';

/**
 * returns workflow info of an mff file
 *
 * @returns {Promise<Object>} contains workflow info
 */
async function getWorkflowInfo(entityId, fileId) {
  const url = `${baseUrl}/mff/workflow/${entityId}/${fileId}`;
  let data;
  try {
    ({ data } = await http.get(url));
  } catch (error) {
    console.log(error);
    return {};
  }
  if (!('settings' in data)) {
    return {};
  }
  const keys = [
    'name', 'description', 'categories', 'padding',
    'filterSettings', 'artifactDetection', 'avgRef',
  ];
  const noMissingKeys = keys.every((key) => key in data.settings);
  return noMissingKeys ? data : {};
}

/**
 * returns history info of an mff file
 *
 * @returns {Promise<Object>} contains history info
 */
async function getHistoryInfo(entityId, fileId) {
  const url = `${baseUrl}/mff/history/${entityId}/${fileId}`;
  let data;
  try {
    ({ data } = await http.get(url));
  } catch (error) {
    console.log(error);
    return {};
  }
  return data;
}

/**
 * returns event codes of an mff file
 *
 * @param {Object} fileProps - file properties
 * @param {string} fileProps.fileId - an mff file ID
 * @param {?string} fileProps.experimentId - an experiment ID
 * @param {string} fileProps.patientId - a patient ID
 * @returns {string[]} - event codes included in the passed mff file
 */
async function getEventCodes(fileProps) {
  const { fileId, experimentId, patientId } = fileProps;
  const entityId = experimentId !== null ? experimentId : patientId;
  const url = `${baseUrl}/mff/events/codes/${entityId}/${fileId}`;
  try {
    const response = await http.get(url);
    const { data } = response;
    return data.sort();
  } catch (error) {
    return [];
  }
}

/**
 * Checks mff support for available workflows
 * that accept an mff file as an input.
 *
 * @param {Object} fileProps - workflow name
 * @param {string} fileProps.fileId - a file ID
 * @param {?string} fileProps.experimentId - an experiment ID
 * @param {string} fileProps.patientId - a patient ID
 * @returns {Object.<string, boolean|string>} indicates whether the mff
 * is supported for each workflow or not and why (if it is not supported).
 */
async function checkMffSupport({ experimentId, patientId, fileId }) {
  const entityId = experimentId !== null ? experimentId : patientId;
  const url = `${baseUrl}/mff/supportedWorkflows/${entityId}/${fileId}`;
  const { data } = await http.get(url);
  return data;
}

/**
 * get filename from a URL string
 *
 * @param {string} url - url string
 * @returns {string} - filename
 */
function filenameFromUrl(url) {
  return url.substring(url.indexOf('::') + 2, url.length);
}

/**
 * get file url from a file name, tag and experiment id
 *
 * @param {string} name - a file name
 * @param {string} tag - a tag
 * @param {string} experimentId - an experiment id
 * @returns {string} - a file url
 */
function urlFromFilename(name, tag, experimentId) {
  return `${experimentId}/${tag}/${name}::${name}`;
}

export {
  getWorkflowInfo,
  getHistoryInfo,
  getEventCodes,
  checkMffSupport,
  filenameFromUrl,
  urlFromFilename,
};
