import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from '@material-table/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { objToString } from '../../services/utils';

const { isFinite, isInteger } = Number;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

/**
 * FileInfoDialog
 *
 * renders a modal dialog to display info of an mff file
 *
 * @param {object} props - component's properties
 * @param {function} props.open - invoked when dialog is opened
 * @param {object} props.info - contains file info
 * @param {function} props.onClose - invoked when dialog is closed
 * @returns {React.Component} - returns a modal dialog containing file info
 */
const FileInfoDialog = ({
  open,
  info,
  onClose,
}) => {
  const classes = useStyles();
  const { file, workflowInfo, historyInfo } = info;
  const generalInfo = file ? {
    name: file.url.split('::')[1],
    type: file.type,
    dateCreated: file.date,
  } : {};

  const validHistoryinfo = historyInfo && typeof historyInfo === 'object';
  const history = validHistoryinfo
    && 'historyEntries' in historyInfo
    && 'entry' in historyInfo.historyEntries
    ? historyInfo.historyEntries.entry : [];

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose();
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="lg">
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={1}
            justify="center"
            alignItems="stretch"
          >
            {
              workflowInfo && Object.entries(workflowInfo).length !== 0 && (
                <Grid item md={12}>
                  <Paper className={classes.paper} elevation={3}>
                    <Typography variant="subtitle1" gutterBottom>
                      {`ERP workflow history ${generalInfo.name}`}
                    </Typography>
                    <MaterialTable
                      columns={[
                        {
                          title: 'Name',
                          field: 'name',
                          render: ({ name, description }) => (
                            <Tooltip title={description}>
                              <span>{name}</span>
                            </Tooltip>
                          ),
                        },
                        {
                          title: 'Categories (event codes)',
                          field: 'categories',
                          render: (rowData) => {
                            const { categories } = rowData;
                            return (
                              <pre
                                style={{
                                  marginTop: '20px',
                                }}
                              >
                                {Object.keys(categories).length === 0 ? 'All' : objToString(categories, '\n')}
                              </pre>
                            );
                          },
                        },
                        {
                          title: 'Time before event [ms]',
                          field: 'leftPadding',
                          render: ({ padding }) => {
                            const { left } = padding;
                            return <span>{isInteger(left) ? left : 'N/A'}</span>;
                          },
                        },
                        {
                          title: 'Time after event [ms]',
                          field: 'rightPadding',
                          render: ({ padding }) => {
                            const { right } = padding;
                            return <span>{isInteger(right) ? right : 'N/A'}</span>;
                          },
                        },
                        {
                          title: 'Highpass filter [Hz]',
                          field: 'highpass',
                          render: ({ filterSettings }) => {
                            const { cutoff, order } = filterSettings;
                            let value;
                            if ('highpass' in cutoff) {
                              const { highpass } = cutoff;
                              value = isFinite(highpass) && isInteger(order) ? highpass : 'No';
                            } else {
                              value = 'No';
                            }
                            return <span>{value}</span>;
                          },
                        },
                        {
                          title: 'Lowpass filter [Hz]',
                          field: 'lowpass',
                          render: ({ filterSettings }) => {
                            const { cutoff, order } = filterSettings;
                            let value;
                            if ('lowpass' in cutoff) {
                              const { lowpass } = cutoff;
                              value = isFinite(lowpass) && isInteger(order) ? lowpass : 'No';
                            } else {
                              value = 'No';
                            }
                            return <span>{value}</span>;
                          },
                        },
                        {
                          title: 'Filter order',
                          field: 'order',
                          render: ({ filterSettings }) => {
                            const { cutoff, order } = filterSettings;
                            let value;
                            if ('highpass' in cutoff && 'lowpass' in cutoff) {
                              const { highpass, lowpass } = cutoff;
                              const someFilterEnabled = isFinite(lowpass) || isFinite(highpass);
                              value = someFilterEnabled && isInteger(order) ? order : 'No';
                            } else {
                              value = 'No';
                            }
                            return <span>{value}</span>;
                          },
                        },
                        {
                          title: 'Drop bad segments (ampl. criteria) [uV]',
                          field: 'dropBadSegments',
                          render: ({ artifactDetection }) => {
                            const { dropBadSegments, amplitudeCriteria } = artifactDetection;
                            let value;
                            if (typeof dropBadSegments === 'boolean' && isFinite(amplitudeCriteria)) {
                              value = dropBadSegments ? amplitudeCriteria : 'No';
                            } else {
                              value = 'N/A';
                            }
                            return <span>{value}</span>;
                          },
                        },
                        {
                          title: 'Apply average reference',
                          field: 'avgRef',
                          render: ({ avgRef }) => {
                            let value;
                            if (typeof avgRef === 'boolean') {
                              value = avgRef ? 'Yes' : 'No';
                            } else {
                              value = 'N/A';
                            }
                            return <span>{value}</span>;
                          },
                        },
                      ]}
                      data={[workflowInfo.settings]}
                      options={{
                        showTitle: false,
                        search: false,
                        paging: false,
                      }}
                    />
                  </Paper>
                </Grid>
              )
            }
            <Grid item md={12}>
              <Paper className={classes.paper} elevation={3}>
                <Typography variant="subtitle1" gutterBottom>
                  General information
                </Typography>
                <pre
                  style={{
                    backgroundColor: '#EEE',
                    padding: '10px',
                    margin: '10px',
                  }}
                >
                  {JSON.stringify(generalInfo, null, 2)}
                </pre>
              </Paper>
            </Grid>
            <Grid item md={12}>
              <Paper className={classes.paper} elevation={3}>
                <Typography variant="subtitle1" gutterBottom>
                  History
                </Typography>
                <pre
                  style={{
                    backgroundColor: '#EEE',
                    padding: '10px',
                    margin: '10px',
                  }}
                >
                  {JSON.stringify(history, null, 2)}
                </pre>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOk}
            color="primary"
            variant="contained"
            size="small"
            style={{
              paddingTop: '1px',
              paddingBottom: '1px',
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

FileInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  info: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FileInfoDialog;
