import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

/**
 * TimeZones
 *
 * renders a drop down grouped list to select a time zone
 *
 * @param {Object} props - component's properties
 * @param {function} props.onChange - invoked when a time zone is selected
 * @param {string[]} props.timeZones - a list of time zones
 * @param {string} props.timeZone - a time zone
 * @returns {React.Component} - returns an autocomplete component with grouped time zones
 */
const TimeZones = ({ onChange, timeZones, timeZone }) => {
  const options = timeZones.map((option) => ({
    firstLetter: option[0].toUpperCase(),
    label: option,
  }));
  const currOption = {
    firstLetter: timeZone[0].toUpperCase(),
    label: timeZone,
  };

  return (
    <Autocomplete
      id="grouped-timezones"
      autoHighlight
      options={options}
      disableClearable="true"
      value={currOption}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.label}
      style={{ width: 250 }}
      renderInput={(params) => <TextField {...params} label="Time Zone" variant="outlined" />}
      onChange={onChange}
    />
  );
};

TimeZones.propTypes = {
  onChange: PropTypes.func.isRequired,
  timeZones: PropTypes.arrayOf(PropTypes.string).isRequired,
  timeZone: PropTypes.string.isRequired,
};

export default TimeZones;
