/**
 * @fileOverview Simple component indicating to submit workflow.
 * @author Glue Architectures, Inc.
 *
 * Date: 2019-04-25
 * Copyright: 2019, All Rights Reserved.
 * Please see license file: "license.txt", for specific grants to
 * the Brain Electrophysiology Laboratory Company, LLC.
 */

// General
import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    alignContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3, 2),
  },
}));

export default function SubmitWorkflow() {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.root}>
        <Typography component="p" align="center">
          Click below to submit the workflow!
        </Typography>
      </Paper>
    </div>
  );
}
