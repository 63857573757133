import {
  ErrorMessage,
  Formik,
  Form,
  Field,
} from 'formik';
import { Select, TextField } from 'formik-material-ui';
import MaterialTable from '@material-table/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import {
  Add as AddIcon,
  RemoveCircleOutline as RemoveIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { getAges, displayAges, dbAges } from '../services/ages';
import { getDevices } from '../services/devices';
import {
  getExperimentPatients,
  addExperimentPatient,
  deleteExperimentPatient,
} from '../services/experiments';
import License from '../services/license';
import ConfirmDialog from './modal/ConfirmDialog';

const AddExperimentPatientSchema = Yup.object().shape({
  patientId: Yup.string()
    .matches(/^[a-zA-Z0-9][a-zA-Z0-9_-]*$/, 'begins with [a-zA-Z0-9] followed by [a-zA-Z0-9_-]*')
    .required()
    .min(3)
    .label('Patient ID'),
  firstName: Yup.string()
    .required()
    .max(50)
    .label('First Name'),
  lastName: Yup.string()
    .required()
    .max(50)
    .label('Last Name'),
  age: Yup.string()
    .label('Age')
    .required(),
  deviceId: Yup.string()
    .max(50)
    .label('Device ID'),
});

/**
 * ExperimentPatients.
 *
 * @param {{
 *  experimentId: string,
 *  allowModify: boolean,
 *  refresh: function,
 *  }} - experimentId of the experiment and whether adding patients is allowed
 */
const ExperimentPatients = ({ experimentId, allowModify, refresh }) => {
  const [patients, setPatients] = useState([]);
  // `defaultAge` is set as age default when creating patients
  const [defaultAge, setDefaultAge] = useState('');
  // configuration for the confirm dialog spawned when deleting patients
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [rowToDelete, setRowToDelete] = useState({});
  const [features, setFeatures] = useState(License.defaults());

  useEffect(() => {
    const license = new License();
    license.update()
      .then(() => setFeatures(license.features));
  }, []);

  const fetchPatients = useCallback(async () => {
    const newPatients = await getExperimentPatients(experimentId);
    Object.entries(newPatients).forEach((entry) => {
      const [patientNum, patientData] = entry;
      const patientAge = patientData.age;
      newPatients[patientNum].age = displayAges(patientAge);
    });
    setPatients(newPatients);
  }, [experimentId]);

  useEffect(() => {
    fetchPatients();
  }, [fetchPatients]);

  const [devices, setDevices] = useState([]);

  const fetchDevices = useCallback(async () => {
    const newDevices = await getDevices({ unowned: true, disabled: false });
    setDevices(newDevices);
  }, []);

  React.useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  const [agesLookup, setAgesLookup] = useState({});

  const fetchAges = useCallback(async () => {
    const ages = await getAges();
    const thisDefaultAge = ages.includes('adult') ? displayAges('adult') : ages[0];
    const lookup = Object.fromEntries(ages.map((a) => [displayAges(a), displayAges(a)]));
    setDefaultAge(thisDefaultAge);
    setAgesLookup(lookup);
  }, []);

  React.useEffect(() => {
    fetchAges();
  }, [fetchAges]);

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = async () => {
    // Update the devices
    await fetchDevices();
    // Update age categories
    await fetchAges();
    setModalOpen(true);
  };
  const handleModalClose = () => setModalOpen(false);

  const handleSubmit = async (newPatient, { setFieldError, setSubmitting }) => {
    try {
      const age = dbAges(newPatient.age);
      const patient = { ...newPatient, age };
      await addExperimentPatient(experimentId, patient);
      fetchPatients();
      await refresh();
      handleModalClose();
    } catch (err) {
      if (err.response && err.response.status === 409) {
        setFieldError('patientId', 'Participant ID already in use');
        setSubmitting(false);
      } else {
        toast.error(err.toString());
      }
    }
  };

  const handleDeletePatient = async () => {
    try {
      await deleteExperimentPatient(experimentId, rowToDelete.patientId);
      await fetchPatients();
      await refresh();
    } catch (e) {
      if (e.response?.status === 400) {
        toast.error('Cannot delete patient that has files');
      } else {
        toast.error('Unexpected error when attempting to remove participant');
      }
    }
    setConfirmDeleteOpen(false);
  };

  const columns = [
    { title: 'Participant ID', field: 'patientId' },
    { title: 'Name', render: (rowData) => `${rowData.firstName} ${rowData.lastName}` },
    { title: 'Age', field: 'age' },
  ];
  const actions = [];
  if (allowModify) {
    actions.push({
      icon: RemoveIcon,
      tooltip: 'Delete a Participant',
      // Open the modal when clicked
      onClick: async (event, row) => {
        setDeleteMessage(`Are you sure you want to delete participant ${row.patientId}?`);
        setRowToDelete(row);
        setConfirmDeleteOpen(true);
      },
    });
  }

  if (features.devices) {
    columns.push({ title: 'Device ID', field: 'deviceID' });
  }

  return (
    <>
      <br />
      <Tooltip title={allowModify ? '' : 'Only the owner may add participants'}>
        <span>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleModalOpen}
            disabled={!allowModify}
          >
            Add Participant
          </Button>
        </span>
      </Tooltip>
      <br />
      <br />
      <MaterialTable
        title="Experiment Participants"
        columns={columns}
        actions={actions}
        data={patients}
        options={{
          paging: false,
          maxBodyHeight: '60vh',
          headerStyle: { position: 'sticky', top: 0 },
        }}
      />
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        fullWidth
      >
        <DialogTitle>Add new participant</DialogTitle>
        <DialogContent>
          <Formik
            /*
             * TODO: using undefined as a default value will trigger warnings about switching
             * between controlled and uncontrolled components. We should figure out what to do about
             * this.
             */
            initialValues={{
              patientId: '',
              firstName: '',
              lastName: '',
              age: defaultAge,
              deviceId: undefined,
            }}
            validationSchema={AddExperimentPatientSchema}
            onSubmit={handleSubmit}
          >
            {({ submitForm }) => (
              <Form>
                <Field
                  component={TextField}
                  name="patientId"
                  label="Participant ID"
                  required
                  fullWidth
                  margin="normal"
                />
                <Field
                  component={TextField}
                  name="firstName"
                  label="First Name"
                  required
                  fullWidth
                  margin="normal"
                />
                <Field
                  component={TextField}
                  name="lastName"
                  label="Last Name"
                  required
                  fullWidth
                  margin="normal"
                />
                {/* Selector for age categories */}
                <FormControl
                  fullWidth
                  margin="normal"
                >
                  <InputLabel>Age Category</InputLabel>
                  <Field
                    component={Select}
                    name="age"
                    label="Age"
                    labelWidth="10"
                  >
                    {
                      Object.keys(agesLookup).map((age) => (
                        <MenuItem key={age} value={age}>{age}</MenuItem>
                      ))
                    }
                  </Field>
                  {/* TODO: fix the styling for this error message */}
                  <ErrorMessage name="age" />
                </FormControl>
                {/* Selector for devices */}
                {
                  features.devices
                  && (
                    <FormControl
                      fullWidth
                      margin="normal"
                    >
                      <InputLabel>Device ID</InputLabel>
                      <Field
                        component={Select}
                        name="deviceId"
                        label="Device ID"
                        labelWidth="10"
                      >
                        <MenuItem value={undefined}>None</MenuItem>
                        {
                          devices.map(({ deviceId }) => (
                            <MenuItem key={deviceId} value={deviceId}>{deviceId}</MenuItem>
                          ))
                        }
                      </Field>
                      {/* TODO: fix the styling for this error message */}
                      <ErrorMessage name="deviceId" />
                    </FormControl>
                  )
                }
                <DialogActions>
                  <Button onClick={handleModalClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={submitForm} color="primary">
                    Add
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        title="Delete Experiment Patient"
        message={deleteMessage}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={() => handleDeletePatient()}
      />
    </>
  );
};

ExperimentPatients.propTypes = {
  experimentId: PropTypes.string.isRequired,
  allowModify: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default ExperimentPatients;
