import { toast } from 'react-toastify';
import http from './httpService';

const baseUrl = '/api/workflow';

/**
 * Fetch active and finished workflows
 *
 * @param {string} username - Username of user
 * @returns {object} contains active and finished workflows
 */
async function fetchWorkflows(username) {
  const fetchActive = http.get(`${baseUrl}/active/${username}`);
  const fetchFinished = http.get(`${baseUrl}/finished`);
  const [active, finished] = await Promise.all([fetchActive, fetchFinished]);
  const { data: activeWorkflows } = active;
  const { data: finishedWorkflows } = finished;
  return { activeWorkflows, finishedWorkflows };
}

/** Set all finished workflows as "viewed" */
async function setFinishedWorkflowsAsViewed() {
  const url = `${baseUrl}/finished`;
  await http.patch(url, { viewed: true });
}

/**
 * Get the number of active and (unviewed) finished workflows
 *
 * @param {string} username - Username of user
 * @param {boolean} markFinishedAsViewed - mark all finished workflows as viewed
 * @returns {object} contains the number of active and (unviewed) finished workflows
 */
async function fetchWorkflowCounts(username, markFinishedAsViewed = false) {
  if (markFinishedAsViewed) {
    await setFinishedWorkflowsAsViewed(username);
  }
  const { activeWorkflows, finishedWorkflows } = await fetchWorkflows(username);
  const { length: active } = activeWorkflows;
  const { length: finished } = finishedWorkflows.filter((workflow) => !workflow.viewed);
  return { active, finished };
}

/**
 * submit a new workflow to the backend
 *
 * @param {object} args - workflow arguments
 * @returns {string} the workflow id
 */
async function runWorkflow(args) {
  const url = `${baseUrl}/submit`;
  try {
    const { data: id } = await http.post(url, args);
    return id;
  } catch (error) {
    const { data: message, status } = error.response;
    if (status === 503) {
      toast.error(message);
    }
    throw error;
  }
}

export {
  fetchWorkflows,
  fetchWorkflowCounts,
  setFinishedWorkflowsAsViewed,
  runWorkflow,
};
