import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Paper,
} from '@material-ui/core';

import Select from '../common/CustomSelect';
import { getAvailableModels } from '../../services/neat';

/**
 * NeatSettingsDialog
 *
 * renders a modal dialog containing workflow settings
 *
 * @param {Object} props - component's properties
 * @param {boolean} props.open - if true, the dialog is open
 * @param {function} props.onClose - invoked when dialog is closed
 * @returns {React.Component} - returns a modal dialog containing a select component
 */
const NeatSettingsDialog = ({ open, onClose }) => {
  const [models, setModels] = useState([]);
  const [model, setModel] = useState('');

  useEffect(() => {
    getAvailableModels().then((availableModels) => {
      setModels(availableModels);
      setModel(availableModels[0]);
    });
  }, []);

  const handleChangeModel = ({ target }) => {
    setModel(target.value);
  };

  const handleRun = () => {
    onClose(model);
  };

  const handleCancel = () => {
    onClose('');
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="xs">
        <DialogTitle>NEAT workflow settings</DialogTitle>
        <DialogContent>
          <Paper elevation={3}>
            <Select
              name="model"
              label="Model"
              value={model}
              options={models}
              onChange={handleChangeModel}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRun}
            color="primary"
            variant="contained"
            size="small"
            style={{
              paddingTop: '1px',
              paddingBottom: '1px',
            }}
          >
            Run
          </Button>
          <Button
            onClick={handleCancel}
            color="primary"
            variant="contained"
            size="small"
            style={{
              paddingTop: '1px',
              paddingBottom: '1px',
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

NeatSettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NeatSettingsDialog;
