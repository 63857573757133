import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  InputAdornment,
  Checkbox,
  Grid,
  FormControlLabel,
} from '@material-ui/core';

/**
 * ArtifactDetection
 *
 * renders controls to specify if bad segment rejection
 * should be enabled in a given ERP workflow run.
 *
 * @param {Object} props - component's properties
 * @param {boolean} props.dropBadSegments - if true, bad segments will be rejected
 * @param {number} props.amplitudeCriteria - peak-to-peak amplitude criterion for bad segment
 * rejection in micro volts.
 * @param {function} props.onChangeDropBadSegments - invoked when setting bad segment rejection
 * @param {function} props.onChangeAmplitudeCriteria - invoked when ampl criteriorn is changed
 * @returns {React.Component} - returns a checkbox next to a text field
 */
const ArtifactDetection = ({
  dropBadSegments,
  amplitudeCriteria,
  onChangeDropBadSegments,
  onChangeAmplitudeCriteria,
}) => {
  const handleSetEnabledDropBadSegments = ({ target }) => {
    onChangeDropBadSegments(target.checked);
  };

  const handleChangeAmplitudeCriteria = ({ target }) => {
    onChangeAmplitudeCriteria(target.valueAsNumber);
  };

  const handleFinishChangeAmplitudeCriteria = ({ target, key }) => {
    if (key === 'Enter') {
      const { valueAsNumber } = target;
      const isValidNumber = !Number.isNaN(valueAsNumber);
      const rounded = isValidNumber ? Math.round(valueAsNumber) : 0;
      onChangeAmplitudeCriteria(rounded < 0 ? 0 : rounded);
    }
  };

  return (
    <Grid container spacing={2} justify="center" alignItems="center">
      <Grid item xs={5}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={dropBadSegments}
              onChange={handleSetEnabledDropBadSegments}
              name="dropBadSeg"
            />
          )}
          label="Drop bad segments"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          id="ampl-criteria"
          disabled={!dropBadSegments}
          label="Amplitude criteria"
          type="number"
          value={amplitudeCriteria}
          onChange={handleChangeAmplitudeCriteria}
          onBlur={handleFinishChangeAmplitudeCriteria}
          onKeyPress={handleFinishChangeAmplitudeCriteria}
          InputProps={{
            inputProps: { min: 0, step: 1 },
            endAdornment: <InputAdornment position="end">uV</InputAdornment>,
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  );
};

ArtifactDetection.propTypes = {
  dropBadSegments: PropTypes.bool.isRequired,
  amplitudeCriteria: PropTypes.number.isRequired,
  onChangeDropBadSegments: PropTypes.func.isRequired,
  onChangeAmplitudeCriteria: PropTypes.func.isRequired,
};

export default ArtifactDetection;
