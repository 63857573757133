import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, styled } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from '@material-table/core';
import {
  Button,
  Box,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import EditErpSettingsDialog from './EditErpSettingsDialog';
import EditOutputFilenameDialog from './EditOutputFilenameDialog';

import {
  getAvailableSettings,
  submitWorkflowSettings,
  deleteWorkflowSettings,
} from '../../services/experiments';
import { objToString } from '../../services/utils';

/**
 * returns available workflow settings in an experiment
 *
 * @param {string} experimentId - experiment ID
 * @returns {object} - contains different type of workflow settings
 */
async function fetchData(experimentId) {
  const data = await getAvailableSettings(experimentId);
  return data;
}

const settingsType = 'erp';
const AddSettingsButton = styled(Button)({
  margin: '10px',
});
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
}));

/**
 * ErpSettingsDialog
 *
 * renders a modal dialog containing workflow settings
 *
 * @param {Object} props - component's properties
 * @param {function} props.open - invoked when dialog is opened
 * @param {function} props.onClose - invoked when dialog is closed
 * @param {string} props.filename - mff name
 * @param {string} props.patientId - a patient id
 * @param {string} props.experimentId - current experiment ID
 * @param {string[]} props.availableCodes - event codes in current mff file
 * @returns {React.Component} - returns a modal dialog containing a table component
 */
const ErpSettingsDialog = ({
  open,
  onClose,
  filename,
  patientId,
  experimentId,
  availableCodes,
}) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [currentSettings, setCurrentSettings] = useState({});
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [outputMffDialogOpen, setOutputMffDialogOpen] = useState(false);

  useEffect(() => {
    fetchData(experimentId).then(({ erp }) => {
      setData(erp);
    });
  }, []);

  const handleAddSettings = () => {
    const defaultSettings = JSON.parse(JSON.stringify(data[0]));
    delete defaultSettings._id;
    delete defaultSettings.uuid;
    defaultSettings.name = 'New Config';
    defaultSettings.description = '';
    defaultSettings.deletable = true;
    setCurrentSettings(defaultSettings);
    setEditDialogOpen(true);
  };

  const handleEditSettings = (id) => {
    setCurrentSettings(data[id]);
    setEditDialogOpen(true);
  };

  const handleDuplicateSettings = async (id) => {
    const settings = JSON.parse(JSON.stringify(data[id]));
    delete settings._id;
    delete settings.uuid;
    settings.deletable = true;
    try {
      await submitWorkflowSettings(experimentId, settingsType, settings);
      const { erp } = await fetchData(experimentId);
      setData(erp);
    } catch (error) {
      console.error(`Unexpected error: ${error.response}`);
      alert('Unexpected error while duplicating workflow settings');
    }
  };

  const handleDeleteSettings = async (id) => {
    const { uuid } = data[id];
    try {
      await deleteWorkflowSettings(experimentId, settingsType, uuid);
      const { erp } = await fetchData(experimentId);
      setData(erp);
    } catch (error) {
      console.error(`Unexpected error: ${error.response}`);
      alert('Unexpected error while deleting workflow settings');
    }
  };

  const handleClickRun = (id) => {
    setCurrentSettings(data[id]);
    setOutputMffDialogOpen(true);
  };

  const handleCloseEditSettingsDialog = async (settings) => {
    if (settings) {
      try {
        await submitWorkflowSettings(experimentId, settingsType, settings);
        const { erp } = await fetchData(experimentId);
        setData(erp);
      } catch (error) {
        console.error(`Unexpected error: ${error.response}`);
        alert('Unexpected error while submitting workflow settings');
      }
    }
    setEditDialogOpen(false);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleCloseOutputMffDialog = (outputFilename) => {
    if (outputFilename) {
      const codes = Object.values(currentSettings.categories);
      const validCodes = codes.filter((code) => availableCodes.includes(code));
      if (codes.length !== 0 && validCodes.length === 0) {
        alert('No valid event codes selected');
      } else {
        if (validCodes.length !== codes.length) {
          alert(`Some event codes are not valid. The following codes will be used: ${JSON.stringify(validCodes)}`);
        }
        setOutputMffDialogOpen(false);
        onClose(currentSettings, outputFilename);
      }
    } else {
      setOutputMffDialogOpen(false);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="xl">
        <DialogTitle>ERP workflow settings</DialogTitle>
        <DialogContent>
          <div>
            <MaterialTable
              columns={[
                {
                  title: 'Name',
                  field: 'name',
                  render: ({ name, tableData }) => {
                    const { description } = data[tableData.id];
                    return (
                      <Tooltip title={description}>
                        <span>{name}</span>
                      </Tooltip>
                    );
                  },
                },
                {
                  title: 'Categories (event codes)',
                  field: 'categories',
                  render: (rowData) => {
                    const { categories } = rowData;
                    return (
                      <div style={{ width: 300, whiteSpace: 'nowrap' }}>
                        <Box overflow="auto" bgcolor="background.paper">
                          {`${Object.keys(categories).length === 0 ? 'All' : objToString(categories, ', ')}`}
                        </Box>
                      </div>
                    );
                  },
                },
                { title: 'Time before event [ms]', field: 'leftPadding' },
                { title: 'Time after event [ms]', field: 'rightPadding' },
                { title: 'Highpass filter [Hz]', field: 'highpass' },
                { title: 'Lowpass filter [Hz]', field: 'lowpass' },
                { title: 'Filter order', field: 'order' },
                { title: 'Drop bad segments (ampl. criteria) [uV]', field: 'dropBadSegments' },
                { title: 'Apply average reference', field: 'avgRef' },
              ]}
              data={
                data.map((obj) => {
                  const { left, right } = obj.padding;
                  const { enabled, cutoff, order } = obj.filterSettings;
                  const { dropBadSegments, amplitudeCriteria } = obj.artifactDetection;
                  return {
                    name: obj.name,
                    deletable: obj.deletable,
                    categories: obj.categories,
                    leftPadding: left * 1000,
                    rightPadding: right * 1000,
                    highpass: enabled.highpass ? cutoff.highpass : 'No',
                    lowpass: enabled.lowpass ? cutoff.lowpass : 'No',
                    order: (enabled.highpass || enabled.lowpass) ? order : '-',
                    dropBadSegments: dropBadSegments ? amplitudeCriteria : 'No',
                    avgRef: obj.avgRef ? 'Yes' : 'No',
                  };
                })
              }
              editable={{
                isDeletable: (rowData) => rowData.deletable,
                onRowDelete: ({ tableData }) => new Promise((resolve) => {
                  setTimeout(() => {
                    handleDeleteSettings(tableData.id);
                    resolve();
                  }, 1000);
                }),
              }}
              actions={[
                {
                  icon: 'add',
                  tooltip: 'Add Settings',
                  isFreeAction: true,
                  onClick: () => handleAddSettings(),
                },
                () => ({
                  icon: 'play_circle_outline',
                  tooltip: 'Run workflow',
                  onClick: (event, { tableData }) => handleClickRun(tableData.id),
                }),
                () => ({
                  icon: 'library_add',
                  tooltip: 'Duplicate Settings',
                  onClick: (event, { tableData }) => handleDuplicateSettings(tableData.id),
                }),
                () => ({
                  icon: 'edit',
                  tooltip: 'Edit Settings',
                  onClick: (event, { tableData }) => handleEditSettings(tableData.id),
                }),
              ]}
              components={{
                Action: (props) => {
                  if (props.action.tooltip === 'Add Settings') {
                    return (
                      <AddSettingsButton
                        onClick={(event) => props.action.onClick(event, props.data)}
                        color="primary"
                        variant="contained"
                      >
                        Add Settings
                      </AddSettingsButton>
                    );
                  }
                  // Use the regular action component for inline actions
                  return (
                    <MTableAction
                      {...props} // eslint-disable-line react/jsx-props-no-spreading
                    />
                  );
                },
              }}
              localization={{
                body: {
                  deleteTooltip: 'Delete Settings',
                  editRow: {
                    deleteText: 'Are you sure you want to delete?',
                  },
                },
              }}
              options={{
                showTitle: false,
              }}
            />
            <EditErpSettingsDialog
              open={editDialogOpen}
              availableCodes={availableCodes}
              value={currentSettings}
              onClose={handleCloseEditSettingsDialog}
            />
            <EditOutputFilenameDialog
              open={outputMffDialogOpen}
              filename={filename}
              patientId={patientId}
              experimentId={experimentId}
              settingsName={currentSettings.name}
              onClose={handleCloseOutputMffDialog}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            color="primary"
            variant="contained"
            size="small"
            style={{
              paddingTop: '1px',
              paddingBottom: '1px',
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ErpSettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  experimentId: PropTypes.string.isRequired,
  availableCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ErpSettingsDialog;
