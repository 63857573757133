import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar, MTableAction } from '@material-table/core';
import {
  FormControlLabel,
  Grid,
  Checkbox,
  IconButton,
  Icon,
  Tooltip,
} from '@material-ui/core';

function arrayToObj(array) {
  return Object.fromEntries(array.map((obj) => [obj.category, obj.eventCode]));
}

function objToArray(obj) {
  return Object.entries(obj).map(([key, value]) => ({ category: key, eventCode: value }));
}

/**
 * CategoriesTable component
 *
 * @param {object} props - component properties
 * @param {string} props.availableCodes - available event codes in an mff file
 * @param {string} props.categories - selected categories and event codes
 * @returns {React.Component} - returns a table for selecting categories and event codes
 */
const CategoriesTable = ({ availableCodes, categories, onChangeCategories }) => {
  const [data, setData] = useState([]);
  const [useAllCodes, setUseAllCodes] = useState(true);
  const [codesLookup, setCodesLookup] = useState({});
  // `defaultCode` is set as event code default when creating categories
  const [defaultCode, setDefaultCode] = useState('');
  const ref = useRef({
    state: {
      showAddRow: false,
      lastEditingRow: undefined,
    },
  });

  useEffect(() => {
    const lookup = Object.fromEntries(availableCodes.map((code) => [code, code]));
    setCodesLookup(lookup);
    setDefaultCode(availableCodes[0]);
  }, [availableCodes]);

  useEffect(() => {
    const noCategories = Object.entries(categories).length === 0;
    const defaultData = noCategories ? codesLookup : categories;
    setUseAllCodes(noCategories);
    setData(objToArray(defaultData));
  }, [categories, codesLookup]);

  const handleAllEventsChange = ({ target }) => {
    const { checked } = target;
    setUseAllCodes(checked);
    onChangeCategories(checked ? {} : codesLookup);
  };

  const handleAddCategory = (newData) => {
    onChangeCategories(arrayToObj([...data, newData]));
  };

  const handleEditCategory = (newData, index) => {
    const dataUpdate = [...data];
    dataUpdate[index] = newData;
    onChangeCategories(arrayToObj(dataUpdate));
  };

  const handleDeleteCategory = async (index) => {
    const dataUpdate = data.filter((_, i) => i !== index);
    onChangeCategories(arrayToObj(dataUpdate));
  };

  const validateCategory = (name, index) => {
    let isValid;
    let helperText;
    if (name && name !== '') {
      const alreadyExists = data.some(({ category }, i) => name === category && index !== i);
      if (!alreadyExists) {
        isValid = true;
        helperText = '';
      } else {
        isValid = false;
        helperText = 'Category cannot be duplicated';
      }
    } else {
      isValid = false;
      helperText = 'Category cannot be empty';
    }
    return { isValid, helperText };
  };

  return (
    <div>
      <MaterialTable
        tableRef={ref}
        columns={[
          {
            title: 'Category',
            field: 'category',
            validate: (rowData) => {
              const { tableData } = rowData;
              return validateCategory(rowData.category, tableData ? tableData.id : -1);
            },
            initialEditValue: 'New Category',
          },
          {
            title: 'Code', field: 'eventCode', lookup: codesLookup, initialEditValue: defaultCode,
          },
        ]}
        editable={{
          isEditable: () => !useAllCodes,
          onRowAdd: !useAllCodes ? (newData) => new Promise((resolve) => {
            setTimeout(() => {
              const { isValid } = validateCategory(newData.category, -1);
              if (isValid) {
                handleAddCategory(newData);
              }
              resolve();
            }, 1000);
          }) : undefined,
          onRowUpdate: (newData, oldData) => new Promise((resolve) => {
            setTimeout(() => {
              const { id } = oldData.tableData;
              const { isValid } = validateCategory(newData.category, id);
              if (isValid) {
                handleEditCategory(newData, id);
              }
              resolve();
            }, 1000);
          }),
        }}
        data={data}
        actions={[
          {
            icon: 'add_box',
            tooltip: 'Add Category',
            isFreeAction: true,
            disabled: useAllCodes,
            hidden: !useAllCodes,
          },
          {
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (event, { tableData }) => {
              handleDeleteCategory(tableData.id);
            },
            disabled: data.length === 1 || useAllCodes,
          },
        ]}
        options={{
          minBodyHeight: 130,
          maxBodyHeight: 130,
          showTitle: false,
          padding: 'dense',
          paging: false,
          headerStyle: { position: 'sticky', top: 0 },
        }}
        localization={{
          body: {
            addTooltip: 'Add Category',
          },
        }}
        components={{
          Toolbar: (props) => (
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={1}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={useAllCodes}
                      name="useAllCodes"
                      disabled={ref.current.state.showAddRow || ref.current.state.lastEditingRow}
                      onChange={handleAllEventsChange}
                    />
                  )}
                  label="ALL"
                />
              </Grid>
              <Grid item xs={11}>
                <div
                  style={{
                    height: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <MTableToolbar {...props} />
                </div>
              </Grid>
            </Grid>
          ),
          Action: (props) => {
            if (typeof props.action === 'object' && props.action.icon === 'delete') {
              return (
                <Tooltip title={props.action.tooltip}>
                  <span style={{ order: 2 }}>
                    <IconButton
                      aria-label={props.action.icon}
                      size="small"
                      onClick={(event) => props.action.onClick(event, props.data)}
                      disabled={data.length === 1 || useAllCodes}
                    >
                      <Icon>{props.action.icon}</Icon>
                    </IconButton>
                  </span>
                </Tooltip>
              );
            }
            // Use the regular action component for inline actions
            return (
              <MTableAction
                {...props} // eslint-disable-line react/jsx-props-no-spreading
              />
            );
          },
        }}
      />
    </div>
  );
};

CategoriesTable.propTypes = {
  availableCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  categories: PropTypes.objectOf(PropTypes.string).isRequired,
  onChangeCategories: PropTypes.func.isRequired,
};

export default CategoriesTable;
